import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import { DistrictForm } from './DistrictForm'

interface TitleProps {
  record?: any
}

const Title: FC<TitleProps> = ({ record }) => <span>Новый документ</span>

const DistrictCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <DistrictForm />
    </Create>
  )
}

export default DistrictCreate
