import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, TextInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import CountryField from '../../components/fields/CountryField'
import { isDandbProject } from '../../components/list/tree/utils'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source="name:$contL" label={'Поиск...'} alwaysOn resettable />
    <CountryInput source="countryId" label={'Страна'} resettable alwaysOn />
    <RegionInput source="regionId" label={'Регион'} resettable alwaysOn />
  </Filter>
)

const DistrictList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Районы"
      empty={<EmptyList title={'Нет районов'} description={'Вы можете добавить район'} buttonText={'Добавить'} />}
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'name'} label={'Название'} />
        {isDandbProject() && <CountryField source={'country.name'} label={'Страна'} />}
        <TextField source={'region.name'} label={'Регион'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default DistrictList
