import * as React from 'react'
import * as _ from 'lodash'
import { SimpleForm, required, TextInput, BooleanInput, NumberInput, DateTimeInput, SelectInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import FeatureTemplateInput from 'src/components/inputs/FeatureTemplateInput'
import RestaurantInput from '../../components/inputs/RestaurantInput'
import { FeatureTemplateType } from '../../types/enum/FeatureTemplateType'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { httpClient } from '../../common/providers/dataProvider'
import CountryInput from 'src/components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'
import { ServiceTypeList } from '../../types/enum/ServiceType'
import { useRecordContext } from 'ra-core'

type Props = {
  isCreation: boolean
}

const FeatureTemplateFields = (props: Props) => {
  const record = useRecordContext()
  const form = useFormContext()
  const formValues = form.watch()
  const [valueType, setValueType] = useState<FeatureTemplateType | null>(null)
  //
  const isOccasion = useMemo(() => window.location.href.includes('occasion'), [window.location.href])

  useEffect(() => {
    if (_.isNumber(formValues.templateId)) {
      getValueType(formValues.templateId)
    }
  }, [formValues.templateId])

  const getValueType = async (templateId: number) => {
    try {
      const res = await httpClient(
        `${process.env.REACT_APP_API_URL || ''}/admin/api/${isOccasion ? 'occasion' : 'feature'}-template/${templateId}`,
        {
          method: 'GET',
        },
      )

      if (res.status === 200) {
        setValueType(res.json.type)
      }
    } catch (err) {
      throw err
    }
  }

  const ValueField = useCallback(() => {
    switch (valueType) {
      case FeatureTemplateType.BOOLEAN:
        return <BooleanInput source={'value'} label={'Содержимое'} validate={required()} />
      case FeatureTemplateType.DATE:
      case FeatureTemplateType.LESS_THAN_DATE:
      case FeatureTemplateType.GREATER_THAN_DATE:
        return <DateTimeInput source={'value'} label={'Содержимое'} validate={required()} />
      case FeatureTemplateType.NUMBER:
      case FeatureTemplateType.LESS_THAN_NUMBER:
      case FeatureTemplateType.GREATER_THAN_NUMBER:
        return <TextInput source={'value'} label={'Содержимое'} validate={required()} />
      case FeatureTemplateType.CURRENCY:
      case FeatureTemplateType.LESS_THAN_CURRENCY:
      case FeatureTemplateType.GREATER_THAN_CURRENCY:
        return <NumberInput source={'value'} label={'Содержимое'} validate={required()} />
      case FeatureTemplateType.STRING:
      default:
        return <TextInput source={'value'} label={'Содержимое'} />
    }
  }, [valueType])

  const resetTemplateAndRestaurantFields = () => {
    form.resetField('templateId')
    form.resetField('restaurantId')
  }

  return (
    <>
      <CountryInput
        source={'countryId'}
        label={'Страна'}
        validate={props.isCreation ? required() : null}
        onChange={resetTemplateAndRestaurantFields}
        fullWidth
      />
      <SelectInput
        source={'serviceType'}
        label={'Тип ресторана'}
        choices={ServiceTypeList}
        validate={props.isCreation ? required() : []}
        onChange={resetTemplateAndRestaurantFields}
        fullWidth
        helperText={'Выбирайте для упрощенного поиска ресторанов'}
      />
      <RestaurantInput
        source={'restaurantId'}
        label={'Ресторан'}
        filter={{
          ...(formValues.serviceType || record?.restaurant?.serviceType
            ? {
                serviceType: formValues.serviceType || record?.restaurant?.serviceType,
              }
            : {}),
          ...getCountryIdFilterBasedOnProject(
            formValues.countryId || record?.restaurant?.serviceType,
            'feature.countryId',
          ),
        }}
        validate={required()}
        fullWidth
      />
      <FeatureTemplateInput
        source={'templateId'}
        isOccasion={isOccasion}
        filter={{
          ...(formValues.serviceType || record?.restaurant?.serviceType
            ? {
                'feature.serviceType': formValues.serviceType || record?.restaurant?.serviceType,
              }
            : {}),
          ...getCountryIdFilterBasedOnProject(
            formValues.countryId || record?.restaurant?.serviceType,
            'feature.countryId',
          ),
        }}
        fullWidth
        validate={required()}
      />
      <ValueField />
    </>
  )
}

export const RestaurantFeatureForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <FeatureTemplateFields {...props} />
    </SimpleForm>
  )
}
