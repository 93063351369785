export enum Application {
  ClientApp = 'ClientApp',
  VendorApp = 'VendorApp',
}

export const ApplicationItemList = [
  { id: Application.ClientApp, name: 'Клиентское приложение' },
  { id: Application.VendorApp, name: 'Приложение вендор' },
]

export enum AppState {
  Active = 'active',
  Maintenance = 'maintenance',
}

export const AppStateList = [
  { id: AppState.Active, name: 'Активно' },
  { id: AppState.Maintenance, name: 'Обслуживание' },
]

export enum Store {
  PlayStore = 'PlayStore',
  AppStore = 'AppStore',
  AppGallery = 'AppGallery',
  RuStore = 'RuStore',
}

export const StoreItemList = [
  { id: Store.PlayStore, name: 'Play Store' },
  { id: Store.AppStore, name: 'App Store' },
  { id: Store.AppGallery, name: 'App Gallery' },
  { id: Store.RuStore, name: 'RuStore' },
]
