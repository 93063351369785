import * as React from 'react'
import { FC } from 'react'
import {
  Filter,
  List,
  ListProps,
  TextField,
  DateField,
  TextInput,
  SelectInput,
  SelectField,
  NumberField,
} from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { ModificationGroupTypeList } from '../../types/enum/ModificationGroupType'
import UnitLinkField from '../../components/fields/UnitLinkField'
import BrandLinkField from '../../components/fields/BrandLinkField'
import BrandInput from '../../components/inputs/BrandInput'
import UnitInput from '../../components/inputs/UnitInput'
import ProductLinkField from '../../components/DropdownMenu/ProductLinkField'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source={'name:$contL'} label={'Название'} alwaysOn resettable />
    <BrandInput source={'unit.brand.id'} label={'Бренд'} alwaysOn resettable />
    <UnitInput source={'unitId'} label={'Заведение'} alwaysOn resettable />
    <SelectInput source={'type'} label={'Тип'} choices={ModificationGroupTypeList} />
  </Filter>
)

const ModificationGroupList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Добавки к блюдам"
      empty={
        <EmptyList
          title={'Нет добавок к блюдам'}
          description={'Вы можете добавить добавку'}
          buttonText={'Добавить добавку'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="name" label={'Название'} />
        <BrandLinkField mainSource="unit.brand" label={'Бренд'} />
        <UnitLinkField source="unit.name" label={'Заведение'} />
        <ProductLinkField mainSource="product" label={'Блюдо'} />
        <SelectField source="type" label={'Тип'} choices={ModificationGroupTypeList} />
        <NumberField source="sort" label={'Сортировка'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default ModificationGroupList
