import * as React from 'react'
import { FieldProps, RaRecord } from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import get from 'lodash/get'

interface Props<RecordType extends RaRecord = any> extends FieldProps {
  mainSource?: string
}

const ProductCategoryLinkField = (props: Props) => {
  return (
    <LinkField
      link={(record) => `/product-category/${get(record, props.source ?? 'category.id')}`}
      source={props.source ?? 'brand.id'}
      value={(record) =>
        props.mainSource ? get(record, props.mainSource ?? 'category')?.name : record?.category?.name
      }
      label={props.label}
    />
  )
}

export default ProductCategoryLinkField
