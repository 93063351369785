import * as React from 'react'
import { SimpleForm, required, TextInput } from 'react-admin'
import RestaurantInput from '../../components/inputs/RestaurantInput'

type Props = {
  isCreation: boolean
}

export const ApiKeyForm = (props: Props) => {
  return (
    <SimpleForm {...(props as any)} redirect={'list'}>
      <RestaurantInput
        source={'restaurantId'}
        label={'Ресторан'}
        validate={required()}
        fullWidth
        disabled={!props.isCreation}
      />
      <TextInput
        source={'key'}
        label={'Ключ'}
        helperText={props.isCreation ? 'Чтобы сгенерировать автоматически оставьте это поле пустым' : ''}
        fullWidth
        disabled={!props.isCreation}
      />
    </SimpleForm>
  )
}
