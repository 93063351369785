import { LocationCityOutlined } from '@mui/icons-material'
import DistrictList from './DistrictList'
import DistrictEdit from './DistrictEdit'
import DistrictCreate from './DistrictCreate'

export default {
  create: DistrictCreate,
  edit: DistrictEdit,
  list: DistrictList,
  icon: LocationCityOutlined,
}
