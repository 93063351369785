import * as React from 'react'
import * as _ from 'lodash'
import { useRecordContext } from 'ra-core'
import { DateField, Pagination, ReferenceManyField, TextField } from 'react-admin'
import RelatedList from '../list/RelatedList'
import Typography from '@mui/material/Typography'

export const RestaurantAdminList = () => {
  const record = useRecordContext()

  if (_.isNil(record?.id)) {
    return <></>
  }
  return (
    <>
      <Typography variant={'h6'} sx={{ ml: 1, mb: 1 }}>
        Пользователи ресторана
      </Typography>
      <ReferenceManyField
        reference={`restaurant/admins/${record.id}`}
        target="id"
        sort={{ field: 'createdAt', order: 'DESC' }}
        pagination={<Pagination />}
        perPage={50}
      >
        <RelatedList
          record={record}
          hasCreate={false}
          emptyTitle={'Пока нет пользователей'}
          bulkActionButtons={false}
          rowClick={(id) => `/admin/${id}`}
        >
          <TextField source="id" label={'ID'} />
          <TextField source="email" label={'Email'} />
          <DateField source="createdAt" label={'Время'} showTime={true} />
        </RelatedList>
      </ReferenceManyField>
    </>
  )
}
