import * as React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  Pagination,
  ReferenceManyField,
  SelectField,
  useRecordContext,
} from 'react-admin'
import * as _ from 'lodash'
import Box from '@mui/material/Box'
import RelatedList from 'src/components/list/RelatedList'
import PriceField from 'src/components/fields/PriceField'
import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import PartnerLinkField from 'src/components/fields/PartnerLinkField'
import RestaurantLinkField from 'src/components/fields/RestaurantLinkField'
import PercentField from 'src/components/fields/PercentField'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import UserField from 'src/components/fields/UserField'
import { BanquetStatusList } from 'src/types/enum/BanquetStatus'

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}

const BanquetOffers = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="banquet-offer"
      target="banquetId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={5}
    >
      <RelatedList
        record={record}
        resource={'banquet-offer'}
        hasCreate={false}
        emptyTitle={'Пока нет предложений'}
        bulkActionButtons={false}
      >
        <IdShowButtonField source={'id'} type={'show'} />
        <PartnerLinkField source={'partner'} label={'Партнер'} />
        <RestaurantLinkField source={'restaurant'} label={'Ресторан'} />
        <PercentField source={'discountBar'} label={'Скидка на бар'} />
        <PercentField source={'discountKitchen'} label={'Скидка на кухню'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const DistrictsField = (_props: any) => {
  const record = useRecordContext()

  if (!_.isArray(record.districts) || _.isEmpty(record.districts)) {
    return null
  }
  return <Typography>{record.districts.map((district: any) => district.name).join(', ')}</Typography>
}

const Index = () => {
  return (
    <Show emptyWhileLoading>
      <SimpleShowLayout>
        <TextField source={'region.name'} label={'Регион'} />
        <DistrictsField label={'Районы'} />
        <UserField
          source={'userId'}
          sourceId={'userId'}
          sourceName={'user.name'}
          sourcePhone={'user.phone'}
          label={'Клиент'}
        />
        <SelectField source={'status'} label={'Статус'} choices={BanquetStatusList} />
        <TextField source={'numOfPersons'} label={'Количество персон'} />
        <PriceField source={'amountPerPerson'} label={'Депозит за 1 персону'} />
        <PriceField source={'depositAmount'} label={'Сумма депозита'} />
        <DateField source={'time'} label={'Время проведения'} showTime />
        <DateField source={'createdAt'} label={'Создана'} showTime />
        <Header>Предложения</Header>
        <BanquetOffers />
      </SimpleShowLayout>
    </Show>
  )
}

export default Index
