import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import PromoCodeSubscriptionForm from 'src/resources/promo-code-subscription/PromoCodeSubscriptionForm'

interface TitleProps {
  record?: any
}

const Title = (props: TitleProps) => <span>Новый Промокод подписки</span>

const PromoCodeSubscriptonCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect="list" title={<Title />}>
      <PromoCodeSubscriptionForm isCreation />
    </Create>
  )
}

export default PromoCodeSubscriptonCreate
