export enum ServiceType {
  Takeaway = 'takeaway',
  Delivery = 'delivery',
  Restaurants = 'restaurants',
  Coffee = 'coffee',
}

export const ServiceTypeList = [
  { id: ServiceType.Takeaway, name: 'Самовывоз' },
  { id: ServiceType.Delivery, name: 'Доставка' },
  { id: ServiceType.Restaurants, name: 'Рестораны' },
  { id: ServiceType.Coffee, name: 'Кафе' },
]
