import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, TextInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import CountryField from '../../components/fields/CountryField'
import CountryInput from 'src/components/inputs/CountryInput'
import { isDandbProject } from '../../components/list/tree/utils'

const exporter = (posts: any[]) => {
  return csvExporter({
    columns: {
      name: 'Название',
      slug: 'URI',
      published: 'Опубликовано',
      createdAt: 'Создано',
    },
    data: posts.map((item: any) => ({
      ...item,
      published: item.published ? 'Да' : 'Нет',
      createdAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt),
    })),
    fileName: 'Category',
  })
}

const _Filter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="name:$contL" label={'Поиск'} variant={'outlined'} alwaysOn resettable />
      <CountryInput source={'countryId'} label={'Страна'} alwaysOn resettable />
    </Filter>
  )
}

const CategoryList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Товарные разделы"
      sort={{ field: 'id', order: 'DESC' }}
      empty={<EmptyList title={'Нет'} description={'Вы можете добавить'} buttonText={'Добавить'} />}
    >
      <Datagrid bulkActionButtons={false} rowClick={'edit'}>
        <TextField source={'id'} label={'ID'} />
        <TextField source={'name'} label={'Название'} />
        {isDandbProject() && <CountryField source={'country.name'} label={'Страна'} />}
        <VisibilityField source={'published'} label={'Статус'} />
      </Datagrid>
    </List>
  )
}

export default CategoryList
