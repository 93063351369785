import ModificationGroupList from './ModificationGroupList'
import { DashboardCustomize } from '@mui/icons-material'
import ModificationGroupCreate from './ModificationGroupCreate'
import ModificationGroupEdit from './ModificationGroupEdit'

export default {
  create: ModificationGroupCreate,
  edit: ModificationGroupEdit,
  list: ModificationGroupList,
  icon: DashboardCustomize,
}
