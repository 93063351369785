import * as React from 'react'
import { FC } from 'react'
import styles from 'src/resources/unit/area/DeliveryZoneEditForm/index.module.scss'
import { required, TextInput, NumberInput, BooleanInput, FormDataConsumer } from 'react-admin'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { DeliveryGeoInput } from 'src/resources/unit/area/DeliveryZoneEditForm/DeliveryGeoInput'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'

function a11yProps(index: number) {
  return {
    id: `area-tab-${index}`,
    'aria-controls': `area-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

export const DeliveryZoneEditForm: FC<any> = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  //
  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: 0 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Настройки" {...a11yProps(0)} />
          <Tab label="Расписание" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container pt={0} pb={0} sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid item xs={8}>
            <div className={styles.imageView}>
              <DeliveryGeoInput name={'geoObjects'} />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Box pl={2} pt={2} pb={2}>
              <TextInput
                source="name"
                label={'Название'}
                fullWidth
                helperText={false}
                variant={'outlined'}
                validate={[required()]}
              />

              <Box mt={2}>
                <BooleanInput
                  source="isFreeDelivery"
                  label={'Есть бесплатная доствка'}
                  fullWidth
                  variant={'outlined'}
                />
                <FormDataConsumer fullWidth={true} variant={'outlined'}>
                  {({ formData }) =>
                    formData.isFreeDelivery && (
                      <NumberInput
                        source="minOrderFreeDelivery"
                        label={'Мин. Сумма заказа для бесплатной доставки'}
                        fullWidth
                        variant={'outlined'}
                        validate={[required()]}
                      />
                    )
                  }
                </FormDataConsumer>
                <NumberInput
                  source="price"
                  label={'Стоимость доставки'}
                  fullWidth
                  variant={'outlined'}
                  validate={[required()]}
                />

                <NumberInput source="minOrderPrice" label={'Мин. сумма заказа'} fullWidth variant={'outlined'} />

                <NumberInput
                  source="deliveryTime"
                  label={'Время доставки (мин.)'}
                  fullWidth
                  variant={'outlined'}
                  validate={[required()]}
                />

                <BooleanInput source={'published'} label={'Активно'} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BooleanInput source="hasSchedule" label={'Есть расписание'} fullWidth />

        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.hasSchedule && <WorkScheduleMultiTimeInput source={'schedule'} label={'Расписание'} />
          }
        </FormDataConsumer>
      </TabPanel>
    </div>
  )
}
