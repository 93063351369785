import * as React from 'react'
import { FC } from 'react'
import { Create, EditProps } from 'react-admin'
import { ApiKeyForm } from './ApiKeyForm'

interface TitleProps {
  record?: any
}

const Title: FC<TitleProps> = ({ record }) => <span>Новый документ</span>

const ApiKeyCreate: FC<EditProps> = (props) => {
  return (
    <Create {...props} redirect={'list'} title={<Title />}>
      <ApiKeyForm isCreation />
    </Create>
  )
}

export default ApiKeyCreate
