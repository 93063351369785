import React, { useState } from 'react'
import { useInput, FieldTitle } from 'ra-core'
import { Button, Typography } from '@mui/material'
import ModalMedia from 'src/components/ModalMedia'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { getMediaPath } from 'src/utils/media'
import { Labeled, InputHelperText } from 'ra-ui-materialui'
import { CommonInputProps } from 'react-admin'
import styles from './index.module.scss'
import { IAsset } from 'src/components/types'
import { AssetEditModal } from 'src/components/AssetEditModal'
import classNames from 'classnames'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FileUploadAcceptType } from 'src/types'

export interface Props extends CommonInputProps {
  accept?: FileUploadAcceptType | FileUploadAcceptType[]
  maxSize?: number
  isMulti?: boolean
  showSetMain?: boolean
  onRemove?: Function
  maxFiles?: number
}

export const MediaInput = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const {
    id,
    field,
    isRequired,
    fieldState,
    formState: { isSubmitted },
  } = useInput({
    ...props,
  })
  const { isTouched, error } = fieldState
  const value = field.value
  const files = props.isMulti ? (value ? (Array.isArray(value) ? value : [value]) : []) : [...(value ? [value] : [])]
  const defaultAsset = props.isMulti ? files.find((file) => file.id === value?.featuredAssetId) : value
  //
  const [activeImage, setActiveImage] = useState(files.length > 0 ? files[0] : null)
  const [modalOpened, setModalOpened] = useState(false)
  const [editAsset, setEditAsset] = useState<IAsset | null>(null)

  const handleAddAsset = (records: IAsset[]) => {
    if (!value) {
      field.onBlur()
    }
    if (!props.isMulti && records.length > 0) {
      field.onChange(records[0])
    } else {
      const fileIds = files.map((i) => i.id)
      const toAdd = records.filter((i) => !fileIds.includes(i.id))
      //
      field.onChange([...files, ...toAdd])
    }
    setModalOpened(false)
  }

  const handleSelect = (e: any, record: IAsset) => {
    setAnchorEl(e.currentTarget)
    setActiveImage(record)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDelete = (record: IAsset) => {
    if (!props.isMulti) {
      field.onChange(null)
    } else {
      const newAssets = (value as IAsset[]).filter((file) => file.id !== record.id)
      field.onChange(newAssets)
    }
    field.onBlur()
    setAnchorEl(null)
  }

  const handleSetFeatured = (record: IAsset) => {
    setAnchorEl(null)
  }

  const handleShow = (record: IAsset) => {
    setAnchorEl(null)
    setEditAsset(record)
  }

  const isImage = (source: string) => {
    const parts = source.split('.')
    const ext = parts[parts.length - 1].replace('.', '').toLowerCase()

    if (['jpg', 'png', 'gif', 'jpeg'].includes(ext)) {
      return true
    }
  }

  const handleCloseEditAsset = (newAsset?: IAsset | null) => {
    if (newAsset) {
      if (props.isMulti) {
        const newAssets = (value as IAsset[]).map((file) => (file.id === newAsset.id ? newAsset : file))
        //
        field.onChange(newAssets)
      } else {
        field.onChange(newAsset)
      }
    }
    setEditAsset(null)
  }

  return (
    <div>
      <Labeled
        label={props.label}
        source={props.source}
        resource={props.resource}
        isRequired={isRequired}
        className={classNames('ra-input', `ra-input-${props.source}`, styles.root)}
      >
        <div>
          <Typography color={'textSecondary'}>
            <FieldTitle label={props.label} source={props.source} resource={props.resource} isRequired={isRequired} />
          </Typography>
          {defaultAsset && (
            <div onClick={(e) => handleSelect(e, defaultAsset)}>
              {isImage(defaultAsset?.source) ? (
                <img
                  className={styles.mainImage}
                  src={`${getMediaPath(defaultAsset?.source)}?preset=small&fpx=${defaultAsset.focalPoint?.x || '0.5'}&fpy=${defaultAsset.focalPoint?.y || '0.5'}`}
                />
              ) : (
                <a href={`${getMediaPath(defaultAsset?.source)}`}>{defaultAsset.name}</a>
              )}
              <IconButton aria-label="more" onClick={(e) => handleSelect(e, defaultAsset)}>
                <MoreVertIcon color={'primary'} />
              </IconButton>
            </div>
          )}
          {!props.isMulti && defaultAsset && (
            <Menu
              id={`asset-menu-${defaultAsset}`}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleShow(defaultAsset)}>Редактировать</MenuItem>
              <MenuItem onClick={() => handleDelete(defaultAsset)}>Удалить</MenuItem>
            </Menu>
          )}

          {props.isMulti && (
            <div className={styles.previews}>
              {files.map((file) => (
                <>
                  <div
                    aria-controls={`asset-menu-${file.id}`}
                    aria-haspopup="true"
                    onClick={(e) => handleSelect(e, file)}
                    className={styles.previewCard}
                  >
                    <img
                      src={`${getMediaPath(file.source)}?preset=tiny&fpx=${file.focalPoint?.x || '0.5'}&fpy=${file.focalPoint?.y || '0.5'}`}
                      className={styles.preview}
                    />
                  </div>
                  <Menu
                    id={`asset-menu-${file.id}`}
                    anchorEl={activeImage?.id === file.id ? anchorEl : null}
                    keepMounted
                    open={activeImage?.id === file.id ? Boolean(anchorEl) : false}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={() => handleShow(file)}>Редактировать</MenuItem>
                    {props.isMulti && props.showSetMain && (
                      <MenuItem onClick={() => handleSetFeatured(file)}>Сделать главной</MenuItem>
                    )}
                    <MenuItem onClick={() => handleDelete(file)}>Удалить</MenuItem>
                  </Menu>
                </>
              ))}
            </div>
          )}
          <Box mt={1}>
            <Button variant="contained" size={'small'} color="primary" onClick={() => setModalOpened(true)}>
              {files.length > 0 ? (props.isMulti ? 'Добавить файл' : 'Изменить файл') : 'Добавить файл'}
            </Button>
          </Box>
          <ModalMedia
            fullWidth
            maxSize={props.maxSize}
            accept={Array.isArray(props.accept) ? props.accept : props.accept ? [props.accept] : undefined}
            isShown={modalOpened}
            onClose={() => setModalOpened(false)}
            isMulti={props.isMulti ?? false}
            maxFiles={props.maxFiles}
            onSelect={handleAddAsset}
          />
          <FormHelperText>
            <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={props.helperText} />
          </FormHelperText>
        </div>
      </Labeled>
      {editAsset != null && (
        <AssetEditModal refresh={false} isShown={true} id={editAsset?.id} onClose={handleCloseEditAsset} />
      )}
    </div>
  )
}

MediaInput.defaultProps = {
  accept: ['image/jpeg', 'image/png', 'image/svg+xml'],
  isMulti: false,
  showSetMain: false,
}
