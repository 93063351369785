import * as React from 'react'
import {
  TextInput,
  required,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  NumberInput,
} from 'react-admin'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { useRecordContext } from 'ra-core'
import * as _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import FormGroup from 'src/components/FormGroup'
import Formatter from 'src/utils/formatters'
import BrandInput from 'src/components/inputs/BrandInput'
import UnitInput from 'src/components/inputs/UnitInput'
import WorkScheduleMultiTimeInput from '../../components/inputs/WorkScheduleMultiTimeInput'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const record = useRecordContext()
  const formValues = form.watch()
  const [schedule, setSchedule] = useState(null)
  const hasSchedule = _.isBoolean(formValues.hasSchedule)
    ? formValues.hasSchedule
    : props.isCreation
      ? false
      : !_.isNil(record?.schedule)

  useEffect(() => {
    form.setValue('hasSchedule', props.isCreation ? false : !_.isNil(record?.schedule))
  }, [props.isCreation, record?.schedule])

  useEffect(() => {
    if (formValues.hasSchedule) {
      form.setValue('schedule', schedule)
    } else {
      if (formValues.schedule || record?.schedule) {
        setSchedule(formValues.schedule || record?.schedule)
      }
      form.setValue('schedule', null)
    }
  }, [formValues.hasSchedule, record?.schedule])

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }

  const handleBrandChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    form.setValue('unitId', '')
  }

  return (
    <>
      <BooleanInput source="published" label={'Активен'} validate={[required()]} fullWidth />
      <BooleanInput source="isAvailable" label={'Доступен для заказа'} validate={[required()]} fullWidth />
      <BrandInput
        source={'brandId'}
        label={'Бренд'}
        resettable
        validate={[required()]}
        onChange={handleBrandChange}
        fullWidth
      />
      <UnitInput
        source={'unitId'}
        label={'Заведение'}
        filter={{ brandId: formValues.brandId || 0 }}
        resettable
        validate={[required()]}
        fullWidth
      />
      <TextInput source="name" label={'Название'} validate={[required()]} fullWidth onChange={handleNameChange} />
      <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
      <NumberInput source="sort" label={'Сортировка'} validate={[required()]} fullWidth />
      <TextInput source="vendorCode" label={'Код категории'} validate={[required()]} fullWidth />
      <FormGroup title={'Товарные разделы'}>
        <ReferenceArrayInput
          filterToQuery={(searchText: string) => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
          allowEmpty={false}
          source="categoriesIds"
          reference="category"
          variant={'outlined'}
          fullWidth={true}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput source="id" label={'Выбранные разделы'} optionText={(i) => (i ? `${i?.name}` : '')} />
        </ReferenceArrayInput>
      </FormGroup>
      <BooleanInput source={'hasSchedule'} label={'Есть расписание'} defaultValue={hasSchedule} />
      {hasSchedule && (
        <FormGroup title={'Время отображения категории'}>
          <WorkScheduleMultiTimeInput source={'schedule'} />
        </FormGroup>
      )}
    </>
  )
}

const ProductCategoryForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <InfoTab />
    </SimpleForm>
  )
}

export default ProductCategoryForm
