import * as React from 'react'
import * as _ from 'lodash'
import get from 'lodash/get'
import { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import { useMemo } from 'react'
import styles from './index.module.scss'
import { format } from 'date-fns'
import { PromoReserveStatusList } from 'src/types/enum/PromoReserveStatus'
import { PromoReserveFieldsMap } from 'src/types/enum/PromoReserveChangedDataField'
import Formatter from 'src/utils/formatters'

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {}

export const PromoReserveChangedDataField = (props: Props) => {
  const record = useRecordContext(props)
  const value = get(record, props.source ?? '')

  const list = useMemo((): Array<{ label: string; value: string; key: string }> => {
    if (Array.isArray(value)) {
      return []
    }
    const keys = Object.keys(value)
    const fieldsMap = PromoReserveFieldsMap as any
    //
    return _.filter(
      keys.map((i) => {
        if (_.isNil(value[i])) {
          return null
        }
        if (i === 'cancelReason' && keys.includes('rejectionReason')) {
          return null
        }
        return {
          label: fieldsMap[i as any],
          value: value[i],
          key: i,
        }
      }),
    ) as unknown as Array<{ label: string; value: string; key: string }>
  }, [value])

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'status':
      case 'prevStatus':
        return PromoReserveStatusList.find((i) => i.id === value)?.name
      case 'total':
        return Formatter.formatFloatPrice(value)
      case 'commission':
        return Formatter.formatIntPrice(value)
      case 'isConfirmExpired':
      case 'clientConfirmedVisit':
        return value ? 'Да' : 'Нет'
      case 'startAt':
      case 'endAt':
      case 'useAt':
      case 'usedAt':
      case 'totalFilledAt':
        if (!value) {
          return ''
        }
        return format(new Date(value), 'dd.MM.y HH:mm xxx')
      default:
        return value ? value : ''
    }
  }
  return (
    <div className={styles.root}>
      {list
        .filter((i) => !Array.isArray(i.value) && !Array.isArray(i.label))
        .map((field, index) => (
          <div key={index}>
            <Typography component="span" variant="body2">
              {field.label ?? ''}: {`${formatValue(field.key, field.value)}` ?? ''}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default PromoReserveChangedDataField
