import * as React from 'react'
import { FC, useMemo } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectField, TextInput, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { FeatureTemplateType, FeatureTemplateTypeList } from '../../types/enum/FeatureTemplateType'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import FeatureInput from '../../components/inputs/FeatureInput'
import RestaurantLinkField from '../../components/fields/RestaurantLinkField'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { ServiceTypeList } from '../../types/enum/ServiceType'

const _Filter = (props: any) => {
  const isOccasion = useMemo(() => window.location.href.includes('occasion'), [window.location.href])
  //
  return (
    <Filter {...props}>
      <TextInput source={'key:$contL'} label={'ID поля'} resettable alwaysOn />
      <TextInput source={'name:$contL'} label={'Название поля'} />
      <TextInput source={'feature.name:$contL'} label={'Название категории'} resettable alwaysOn />
      <FeatureInput source={'featureId'} label={'Категория'} isOccasion={isOccasion} />
      <SelectInput source={'feature.serviceType'} label={'Тип'} choices={ServiceTypeList} resettable alwaysOn />
      <SelectInput source={'type'} label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
      <CountryInput source={'feature.countryId'} label={'Страна'} />
      <RegionInput source={'feature.regionId'} label={'Регион'} />
    </Filter>
  )
}

const ValueField = (props: any) => {
  const record = useRecordContext()

  const value = useMemo(() => {
    switch (record.template.type as FeatureTemplateType) {
      case FeatureTemplateType.BOOLEAN:
        return record.value == 'true' ? 'Да' : 'Нет'
      case FeatureTemplateType.LESS_THAN_NUMBER:
        return `< ${record.value}`
      case FeatureTemplateType.GREATER_THAN_NUMBER:
        return `> ${record.value}`
      case FeatureTemplateType.CURRENCY:
        return `${record.value} ${record.restaurant?.country?.currency || 'руб.'}`
      case FeatureTemplateType.LESS_THAN_CURRENCY:
        return `< ${record.value} ${record.restaurant?.country?.currency || 'руб.'}`
      case FeatureTemplateType.GREATER_THAN_CURRENCY:
        return `> ${record.value} ${record.restaurant?.country?.currency || 'руб.'}`
      case FeatureTemplateType.DATE:
        return moment(record.value).format('DD.MM.YYYY HH:mm')
      case FeatureTemplateType.LESS_THAN_DATE:
        return `< ${moment(record.value).format('DD.MM.YYYY HH:mm')}`
      case FeatureTemplateType.GREATER_THAN_DATE:
        return `> ${moment(record.value).format('DD.MM.YYYY HH:mm')}`
      case FeatureTemplateType.NUMBER:
      case FeatureTemplateType.STRING:
      default:
        return record.value
    }
  }, [record.template?.type])

  return <Typography>{value}</Typography>
}

const RestaurantFeatureList: FC<ListProps> = (props: any) => {
  const isOccasion = useMemo(() => window.location.href.includes('occasion'), [window.location.href])
  //
  return (
    <List
      {...props}
      filters={<_Filter />}
      title={`${isOccasion ? 'Поводы' : 'Особенности'} заведений`}
      empty={
        <EmptyList
          title={`Нет ${isOccasion ? 'поводов' : 'особенностей'} заведений`}
          description={`Вы можете добавить ${isOccasion ? 'повод' : 'особенность'}`}
          buttonText={'Добавить'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="template.key" label={'ID поля'} />
        <TextField source="template.name" label={'Название поля'} />
        <TextField source="template.feature.name" label={'Категория'} />
        <RestaurantLinkField mainSource="restaurant" label={'Ресторан'} />
        <SelectField source="template.feature.serviceType" label={'Тип'} choices={ServiceTypeList} />
        <SelectField source="template.type" label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
        <ValueField label={'Содержимое'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default RestaurantFeatureList
