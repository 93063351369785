import React from 'react'
import { Helmet } from 'react-helmet'
import { Admin } from '@glm/ra-enterprise'
import { AuthProvider } from 'src/common/providers/AuthProvider'
import dataProvider from 'src/common/providers/dataProvider'
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { theme } from 'src/style'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import BanquetReserve from 'src/resources/banquet-reserve'
import BanquetOffer from 'src/resources/banquet-offer'
import Banquet from 'src/resources/banquet'
import PromoReserve from 'src/resources/promo-reserve'
import SubscriptionDescription from 'src/resources/subscription-description'
import Subscription from 'src/resources/subscription'
import UnitSelection from 'src/resources/unit-selection'
import Notification from 'src/resources/notification'
import Refund from 'src/resources/refund'
import Payment from 'src/resources/payment'
import Penalty from 'src/resources/penalty'
import PromoCode from 'src/resources/promo-code'
import PromoCodeSubscription from 'src/resources/promo-code-subscription'
import Complaint from 'src/resources/complaint'
import Tariff from 'src/resources/tariff'
import ViewTemplate from 'src/resources/view-template'
import PromoUnit from 'src/resources/promo-unit'
import PromoRestaurant from 'src/resources/promo-restaurant'
import ApiIntegrationSync from 'src/resources/api-integration-sync'
import Category from 'src/resources/category'
import PromoServiceRestaurants from 'src/resources/promo-service-restaurants'
import Order from 'src/resources/order'
import User from 'src/resources/user'
import Country from 'src/resources/country'
import Region from 'src/resources/region'
import ProductCategory from 'src/resources/product-category'
import Product from 'src/resources/product'
import Review from 'src/resources/review'
import Page from 'src/resources/page'
import Article from 'src/resources/article'
import ArticleCategory from 'src/resources/article-category'
import Unit from 'src/resources/unit'
import Partner from 'src/resources/partner'
import Brand from 'src/resources/brand'
import AdminUser from 'src/resources/admin'
import { YMaps } from '@pbe/react-yandex-maps'
import { AppLayout } from 'src/components/layout'
import Formatter from 'src/utils/formatters'
import { RaRecord } from 'react-admin'
import { Resource } from 'src/components/Resource'
import ReportOrders from 'src/resources/report-orders'
import ReportPromoReserves from 'src/resources/report-promo-reserves'
import UnitSelectionCategory from 'src/resources/unit-selection-category'
import Restaurant from 'src/resources/restaurant'
import PartnerOffer from './resources/partner-offer'
import Accounting from './resources/accounting'
import AppSettings from './resources/app-settings'
import Alert from './resources/alert'
import PromoUnitGroup from './resources/promo-unit-group'
import { getTranslation } from 'src/utils/translation'
import CoffeeShiftReceipt from './resources/coffee-shift-receipt'
import CancelReason from './resources/cancel-reason'
import PromoServiceUnits from 'src/resources/promo-service-units'
import Feature from './resources/feature'
import FeatureTemplate from './resources/feature-template'
import RestaurantFeature from './resources/restaurant-feature'
import ApiKeys from './resources/api-keys'
import Modification from './resources/modification-group'
import District from './resources/district'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const getParsedAppName = () => {
  switch (process.env.REACT_APP_NODE_PROJECT) {
    case 'dandb':
      return 'D&B'
    case 'vsemeda':
    default:
      return 'VsemEda'
  }
}

const getParsedEnvName = () => {
  switch (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) {
    case 'local':
      return 'Local'
    case 'dev':
    case 'development':
      return 'Dev'
    case 'stage':
      return 'Stage'
    case 'prod':
    case 'production':
      return 'Prod'
    default:
      return '?'
  }
}

const App = () => {
  const orderRecordRepresentation = (record: RaRecord) => `№ ${record.number ?? record.id}`
  const PromoReserveRepresentation = (record: RaRecord) => `№ ${record.id}`

  return (
    <div>
      <Helmet>
        <title>
          {getParsedAppName()} {getParsedEnvName()}
        </title>
      </Helmet>
      <YMaps query={{ lang: 'ru_RU' }}>
        <Admin
          lightTheme={theme as any}
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={AuthProvider}
          layout={AppLayout}
          requireAuth
        >
          <Resource
            key={'app-settings'}
            name={'app-settings'}
            {...AppSettings}
            options={{ label: 'Настройки приложениия' }}
            recordRepresentation={(record: RaRecord) => record.name}
          />
          <Resource
            key={'alert'}
            name={'alert'}
            {...Alert}
            options={{ label: 'Алерты' }}
            recordRepresentation={(record: RaRecord) => record.name}
          />
          <Resource
            key={'view-template'}
            name={'view-template'}
            {...ViewTemplate}
            options={{ label: 'Шаблоны вывода' }}
            recordRepresentation={(record: RaRecord) => `${record.name}`}
          />
          <Resource
            key={'view-template-item'}
            name={'view-template-item'}
            options={{ label: 'Шаблоны вывода' }}
            recordRepresentation={(record: RaRecord) => `${record.id}`}
          />
          <Resource
            key={'promo-unit'}
            name={'promo-unit'}
            {...PromoUnit}
            options={{ label: 'Акции заведений доставки' }}
            recordRepresentation={(record) => `${record.unit?.name} - ${record.promo?.name}`}
          />
          <Resource
            key={'promo-unit-group'}
            name={'promo-unit-group'}
            {...PromoUnitGroup}
            options={{ label: 'Группы акций заведения (доставка)' }}
            recordRepresentation={(record) => record.name}
          />
          <Resource
            key={'promo-unit-takeaway'}
            name={'promo-unit-takeaway'}
            {...PromoUnit}
            options={{ label: 'Акции заведений самовывоза' }}
            recordRepresentation={(record) => `${record.unit?.name} - ${record.promo?.name}`}
          />
          <Resource
            key={'promo-unit-takeaway-group'}
            name={'promo-unit-takeaway-group'}
            {...PromoUnitGroup}
            options={{ label: 'Группы акций заведения (самовывоз)' }}
            recordRepresentation={(record) => record.name}
          />
          <Resource
            key={'promo-restaurant'}
            name={'promo-restaurant'}
            {...PromoRestaurant}
            options={{ label: 'Акции ресторанов' }}
            recordRepresentation={(record) => `${record.restaurant?.name} - ${record.promo?.name}`}
          />
          <Resource
            key={'promo-restaurant-coffee'}
            name={'promo-restaurant-coffee'}
            {...PromoRestaurant}
            options={{ label: 'Акции кофеен' }}
            recordRepresentation={(record) => `${record.restaurant?.name} - ${record.promo?.name}`}
          />
          <Resource
            key={'feature'}
            name={'feature'}
            {...Feature}
            options={{ label: 'Особенности' }}
            recordRepresentation={(record) => `${record.filterName} - ${record?.name}`}
          />
          <Resource
            key={'feature-template'}
            name={'feature-template'}
            {...FeatureTemplate}
            options={{ label: 'Поля для особенностей' }}
            recordRepresentation={(record) => record.name}
          />
          <Resource
            key={'restaurant-feature'}
            name={'restaurant-feature'}
            {...RestaurantFeature}
            options={{ label: 'Особенности заведений' }}
            recordRepresentation={(record) => record?.template?.feature?.name}
          />
          <Resource
            key={'occasion'}
            name={'occasion'}
            {...Feature}
            options={{ label: 'Поводы' }}
            recordRepresentation={(record) => `${record.filterName} - ${record?.name}`}
          />
          <Resource
            key={'occasion-template'}
            name={'occasion-template'}
            {...FeatureTemplate}
            options={{ label: 'Поля для поводов' }}
            recordRepresentation={(record) => record.name}
          />
          <Resource
            key={'restaurant-occasion'}
            name={'restaurant-occasion'}
            {...RestaurantFeature}
            options={{ label: 'Поводы заведений' }}
            recordRepresentation={(record) => record?.template?.feature?.name}
          />
          <Resource
            key={'promo-code'}
            name={'promo-code'}
            {...PromoCode}
            options={{ label: 'Промокоды доставки' }}
            recordRepresentation={(record) => `${record?.code}`}
          />
          <Resource
            key={'promo-code-takeaway'}
            name={'promo-code-takeaway'}
            {...PromoCode}
            options={{ label: 'Промокоды самовывоза' }}
            recordRepresentation={(record) => `${record?.code}`}
          />
          <Resource
            key={'subscription-promo-code'}
            name={'subscription-promo-code'}
            {...PromoCodeSubscription}
            options={{ label: 'Промокоды подписки' }}
            recordRepresentation={(record) => `${record?.code}`}
          />
          {/* Delivery Start */}
          <Resource
            key={'order'}
            name={'order'}
            {...Order}
            options={{ label: 'Заказы доставка' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-active'}
            name={'order-active'}
            {...Order}
            options={{ label: 'В обработке' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-confirmed'}
            name={'order-confirmed'}
            {...Order}
            options={{ label: 'Подтвержденные' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-finished'}
            name={'order-finished'}
            {...Order}
            options={{ label: 'Завершенные' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-cancelled'}
            name={'order-cancelled'}
            {...Order}
            options={{ label: 'Отмененные' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-refunding'}
            name={'order-refunding'}
            {...Order}
            options={{ label: 'Ожидают возврата' }}
            recordRepresentation={orderRecordRepresentation}
          />
          {/* Delivery End */}
          {/* Takeaway Start */}
          <Resource
            key={'order-takeaway'}
            name={'order-takeaway'}
            {...Order}
            options={{ label: 'Заказы самовывоз' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-takeaway-active'}
            name={'order-takeaway-active'}
            {...Order}
            options={{ label: 'В обработке' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-takeaway-confirmed'}
            name={'order-takeaway-confirmed'}
            {...Order}
            options={{ label: 'Подтвержденные' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-takeaway-finished'}
            name={'order-takeaway-finished'}
            {...Order}
            options={{ label: 'Завершенные' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-takeaway-cancelled'}
            name={'order-takeaway-cancelled'}
            {...Order}
            options={{ label: 'Отмененные' }}
            recordRepresentation={orderRecordRepresentation}
          />
          <Resource
            key={'order-takeaway-refunding'}
            name={'order-takeaway-refunding'}
            {...Order}
            options={{ label: 'Ожидают возврата' }}
            recordRepresentation={orderRecordRepresentation}
          />
          {/* Takeaway End */}
          <Resource
            key={'user'}
            name={'user'}
            {...User}
            options={{ label: 'Клиенты' }}
            recordRepresentation={(record) => `${record.name ?? ''} ${Formatter.formatPhone(record.phone)}`}
          />
          <Resource
            key={'partner'}
            name={'partner'}
            {...Partner}
            options={{ label: 'Партнеры' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'partner-offer'}
            name={'partner-offer'}
            {...PartnerOffer}
            options={{ label: 'Индивидуальные условия' }}
            recordRepresentation={(record) => `${record.partner.name}`}
          />
          <Resource
            key={'accounting'}
            name={'accounting'}
            {...Accounting}
            options={{ label: 'Бухгалтерия' }}
            recordRepresentation={(record) => `${record.partner.name} - ${record.name}`}
          />
          <Resource
            key={'admin'}
            name={'admin'}
            {...AdminUser}
            options={{ label: 'Администраторы' }}
            recordRepresentation={(record) => `${record.name} ${record.email}`}
          />
          <Resource
            key={'country'}
            name={'country'}
            {...Country}
            options={{ label: 'Страны' }}
            recordRepresentation={(record) => `${getTranslation(record, 'ru')?.name}`}
          />
          <Resource
            key={'region'}
            name={'region'}
            {...Region}
            options={{ label: 'Регионы' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'district'}
            name={'district'}
            {...District}
            options={{ label: 'Районы' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'brand'}
            name={'brand'}
            {...Brand}
            options={{ label: 'Бренды' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'unit'}
            name={'unit'}
            {...Unit}
            options={{ label: 'Заведения' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'product-category'}
            name={'product-category'}
            {...ProductCategory}
            options={{ label: 'Категории товаров' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'product'}
            name={'product'}
            {...Product}
            options={{ label: 'Каталог товаров' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'modification-group'}
            name={'modification-group'}
            {...Modification}
            options={{ label: 'Добавки к блюдам' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'review'}
            name={'review'}
            {...Review}
            options={{ label: 'Отзывы' }}
            recordRepresentation={(record) => `№ ${record.id} ${Formatter.formatDateRelative(record.createdAt)}`}
          />
          <Resource
            key={'promo-service-delivery'}
            name={'promo-service-delivery'}
            {...PromoServiceUnits}
            options={{ label: 'Акции для доставки' }}
            recordRepresentation={(record) => `${record.innerName ?? record.name}`}
          />
          <Resource
            key={'promo-service-takeaway'}
            name={'promo-service-takeaway'}
            {...PromoServiceUnits}
            options={{ label: 'Акции для самовывоза' }}
            recordRepresentation={(record) => `${record.innerName ?? record.name}`}
          />
          <Resource
            key={'promo-service-restaurants'}
            name={'promo-service-restaurants'}
            {...PromoServiceRestaurants}
            options={{ label: 'Акции для ресторанов' }}
            recordRepresentation={(record) => `${record.innerName ?? record.name}`}
          />
          <Resource
            key={'promo-service-coffee'}
            name={'promo-service-coffee'}
            {...PromoServiceRestaurants}
            options={{ label: 'Акции для кофеен' }}
            recordRepresentation={(record) => `${record.innerName ?? record.name}`}
          />
          <Resource key={'promo-public'} name={'promo-public'} />
          <Resource
            key={'reports-promo-reserves'}
            name={'reports-promo-reserves'}
            {...ReportPromoReserves}
            options={{ label: 'Отчет брони' }}
          />
          <Resource
            key={'reports-orders'}
            name={'reports-orders'}
            {...ReportOrders}
            options={{ label: 'Отчет по заказам доставки' }}
          />
          <Resource
            key={'reports'}
            name={'reports'}
            {...ReportOrders}
            options={{ label: 'Отчет по заказам доставки' }}
          />
          <Resource
            key={'category-delivery'}
            name={'category-delivery'}
            {...Category}
            options={{ label: 'Товарные разделы Доставка' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'category-local'}
            name={'category-local'}
            {...Category}
            options={{ label: 'Товарные разделы Ресторана' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'unit-selection'}
            name={'unit-selection'}
            {...UnitSelection}
            options={{ label: 'Подборки ресторанов' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'unit-selection-category'}
            name={'unit-selection-category'}
            {...UnitSelectionCategory}
            options={{ label: 'Категория подборок' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'page'}
            name={'page'}
            {...Page}
            options={{ label: 'Страницы' }}
            recordRepresentation={(record) => `${record.key}`}
          />
          <Resource key={'article-news'} name="article" {...Article} options={{ label: 'Статьи' }} />
          <Resource
            key={'article-category-news'}
            name="article-category"
            {...ArticleCategory}
            options={{ label: 'Категории статей' }}
          />
          <Resource key={'refund'} name={'refund'} {...Refund} options={{ label: 'Платежи клиентов' }} />
          <Resource key={'payment'} name={'payment'} {...Payment} options={{ label: 'Платежи клиентов' }} />

          <Resource
            key={'api-integration-sync'}
            name={'api-integration-sync'}
            {...ApiIntegrationSync}
            options={{ label: 'API синхронизации' }}
          />
          <Resource key={'api-key'} name={'api-key'} {...ApiKeys} options={{ label: 'API Ключи' }} />
          <Resource
            key={'tariff'}
            name={'tariff'}
            {...Tariff}
            options={{ label: 'Тарифы' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'complaint'}
            name={'complaint'}
            {...Complaint}
            options={{ label: 'Жалобы' }}
            recordRepresentation={(record) => `№ ${record.id} ${Formatter.formatDateRelative(record.createdAt)}`}
          />
          <Resource
            key={'penalty'}
            name={'penalty'}
            {...Penalty}
            options={{ label: 'Штрафы' }}
            recordRepresentation={(record) => `№ ${record.id} ${Formatter.formatDateRelative(record.createdAt)}`}
          />
          <Resource key={'notification'} name={'notification'} {...Notification} options={{ label: 'Уведомления' }} />
          <Resource
            key={'restaurant'}
            name={'restaurant'}
            {...Restaurant}
            options={{ label: 'Рестораны' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'restaurant-coffee'}
            name={'restaurant-coffee'}
            {...Restaurant}
            options={{ label: 'Кофейни' }}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            key={'subscription-description'}
            name={'subscription-description'}
            {...SubscriptionDescription}
            options={{ label: 'Описание подписок' }}
          />
          <Resource key={'subscription'} name={'subscription'} {...Subscription} options={{ label: 'Подписки' }} />
          <Resource recordRepresentation={(record) => `${record.name}`} key={'area'} name={'area'} />
          <Resource
            key={'promo-reserve'}
            name={'promo-reserve'}
            {...PromoReserve}
            options={{ label: 'Брони' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-confirmation'}
            name={'promo-reserve-confirmation'}
            {...PromoReserve}
            options={{ label: 'В обработке' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-approved'}
            name={'promo-reserve-approved'}
            {...PromoReserve}
            options={{ label: 'Подтврежденные' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-canceled'}
            name={'promo-reserve-canceled'}
            {...PromoReserve}
            options={{ label: 'Отменные' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-expired'}
            name={'promo-reserve-expired'}
            {...PromoReserve}
            options={{ label: 'Не закрытые' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-coffee'}
            name={'promo-reserve-coffee'}
            {...PromoReserve}
            options={{ label: 'Брони' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-coffee-confirmation'}
            name={'promo-reserve-coffee-confirmation'}
            {...PromoReserve}
            options={{ label: 'В обработке' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-coffee-approved'}
            name={'promo-reserve-coffee-approved'}
            {...PromoReserve}
            options={{ label: 'Подтврежденные' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-coffee-canceled'}
            name={'promo-reserve-coffee-canceled'}
            {...PromoReserve}
            options={{ label: 'Отменные' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource
            key={'promo-reserve-coffee-expired'}
            name={'promo-reserve-coffee-expired'}
            {...PromoReserve}
            options={{ label: 'Не закрытые' }}
            recordRepresentation={PromoReserveRepresentation}
          />
          <Resource key={'banquet'} name={'banquet'} {...Banquet} options={{ label: 'Банкеты' }} />
          <Resource key={'banquet-offer'} name={'banquet-offer'} {...BanquetOffer} options={{ label: 'Предложения' }} />
          <Resource
            key={'banquet-reserve'}
            name={'banquet-reserve'}
            {...BanquetReserve}
            options={{ label: 'Брони Банкетов' }}
          />
          <Resource
            key={'coffee-shift-receipt'}
            name={'coffee-shift-receipt'}
            {...CoffeeShiftReceipt}
            options={{ label: 'Чеки за смену кофейни' }}
          />
          <Resource
            key={'cancel-reason'}
            name={'cancel-reason'}
            {...CancelReason}
            options={{ label: 'Причины отмены' }}
          />
        </Admin>
      </YMaps>
    </div>
  )
}

export default App
