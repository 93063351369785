import * as React from 'react'
import { SimpleForm, required, TextInput } from 'react-admin'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import { useFormContext } from 'react-hook-form'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'

const Form = () => {
  const form = useFormContext()
  const formValues = form.watch()

  return (
    <>
      <CountryInput source={'countryId'} label={'Страна'} validate={required()} fullWidth />
      <RegionInput
        source={'regionId'}
        label={'Регион'}
        validate={required()}
        filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
        fullWidth
      />
      <TextInput source={'name'} label={'Название'} validate={required()} fullWidth />
    </>
  )
}

export const DistrictForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <Form />
    </SimpleForm>
  )
}
