import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField, SelectField, SelectInput } from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { ApplicationItemList, StoreItemList } from '../../types/enum/Application'

const _Filter = (props: any) => (
  <Filter {...props}>
    <SelectInput source={'application'} label={'Приложение'} choices={ApplicationItemList} alwaysOn resettable />
    <SelectInput source={'store'} label={'Магазин приложений'} choices={StoreItemList} alwaysOn resettable />
  </Filter>
)

const AppSettingsList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="Настройки приложения"
      empty={
        <EmptyList
          title={'Нет настроек для приложений'}
          description={'Вы можете добавить настройки'}
          buttonText={'Добавить настройки'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="name" label={'Приложение'} />
        <SelectField source={'store'} label={'Магазин приложений'} choices={StoreItemList} />
        <TextField source="minRequiredVersion" label={'Минимальная допустимая версия'} />
        <TextField source="currentVersion" label={'Текущая версия'} />
        <DateField source="createdAt" label={'Создано'} showTime={true} />
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
      </Datagrid>
    </List>
  )
}

export default AppSettingsList
