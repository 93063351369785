import * as React from 'react'
import { useMemo, useState } from 'react'
import {
  SimpleForm,
  useGetOne,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import { IPromo } from 'src/types/interfaces/IPromo'
import FormGroup from 'src/components/FormGroup'
import UnitInput from 'src/components/inputs/UnitInput'
import ProductCategoriesArrayInput from 'src/components/inputs/ProductCategoriesArrayInput'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import ProductsArrayInput from 'src/components/inputs/ProductsArrayInput'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { PromoUnitStatus, PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { PromoType } from 'src/types/enum/PromoType'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import PromoServiceDeliverySelectPageInput from 'src/components/inputs/PromoServiceDeliverySelectPageInput'
import { ServiceType } from 'src/types/enum/ServiceType'
import PromoCardWithDetails from 'src/components/Promo/PromoCardWithDetails'
import PromoUnitGroupInput from 'src/components/inputs/PromoUnitGroupInput'
import { useRecordContext } from 'ra-core'
import modalFormStyles from 'src/components/ModalForm/index.module.scss'
import CountryInput from 'src/components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject, isDandbProject } from '../../components/list/tree/utils'
import { RecordChanges, RecordType } from '../../components/RecordChangeList'
import Formatter from '../../utils/formatters'
import { PromotionType, PromotionTypeList } from '../../types/enum/PromotionType'

type Props = {
  isCreation: boolean
}

const Details = (props: Props) => {
  const form = useFormContext()
  const record = useRecordContext()
  const countryId = form.watch('countryId')
  //
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])
  //
  const { data: promo } = useGetOne<IPromo>(isTakeaway ? 'promo-service-takeaway' : 'promo-service-delivery', {
    id: form.getValues('promoId') || 0,
  })

  return (
    <Box>
      <IfCanAccess aggregator action={'show'}>
        <SelectInput
          source={'status'}
          label={'Статус'}
          choices={PromoUnitStatusList}
          disabled={!props.isCreation && record.groupId}
        />
      </IfCanAccess>

      {/*{isTakeaway && (*/}
      {/*  <SelectInput source="promotionType" label="Тип акции" choices={PromotionTypeList} validate={required()} />*/}
      {/*)}*/}
      {!props.isCreation && record.groupId && <PromoUnitGroupInput source={'groupId'} label={'Группа'} disabled />}
      {promo && <PromoCardWithDetails promo={promo} showLabel />}
      {promo?.settings.canIncludeCategories && (
        <FormGroup title={'Товарные разделы участвующие'}>
          <BooleanInput
            source={'includeAllCategories'}
            label={'Все меню'}
            disabled={!props.isCreation && record.groupId}
          />
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              !formData?.includeAllCategories && (
                <CategoriesArrayInput
                  filter={{ ...getCountryIdFilterBasedOnProject(countryId), serviceType: ServiceType.Delivery }}
                  source={'includedCategoriesIds'}
                  label={'Выбранные разделы'}
                  disabled={!props.isCreation && record.groupId}
                />
              )
            }
          </FormDataConsumer>
        </FormGroup>
      )}
      {promo?.settings.canExcludeCategories && (
        <FormGroup title={'Товарные разделы исключения'}>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {() => (
              <CategoriesArrayInput
                filter={{ ...getCountryIdFilterBasedOnProject(countryId), serviceType: ServiceType.Delivery }}
                source={'excludedCategoriesIds'}
                label={'Выбранные разделы'}
                disabled={!props.isCreation && record.groupId}
              />
            )}
          </FormDataConsumer>
        </FormGroup>
      )}

      {promo?.settings.canIncludeProductCategories && (
        <FormGroup title={'Категории меню участвующие'}>
          <BooleanInput
            source={'includeAllCategories'}
            label={'Все меню'}
            disabled={!props.isCreation && record.groupId}
          />
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              !formData?.includeAllCategories && (
                <ProductCategoriesArrayInput
                  source={'includedProductCategoriesIds'}
                  label={'Выбранные категории'}
                  filterToQueryField={'name'}
                  filter={{ unitId: formData.unitId }}
                  disabled={!props.isCreation && record.groupId}
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
        </FormGroup>
      )}
      {promo?.settings.canExcludeProductCategories && (
        <FormGroup title={'Категории меню исключения'}>
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) => (
              <ProductCategoriesArrayInput
                source={'excludedProductCategoriesIds'}
                label={'Выбранные категории'}
                filterToQueryField={'name'}
                filter={{ unitId: formData.unitId }}
                disabled={!props.isCreation && record.groupId}
              />
            )}
          </FormDataConsumer>
        </FormGroup>
      )}

      {promo?.settings.canIncludeProducts && (
        <FormGroup title={'Блюда меню участвующие'}>
          <BooleanInput
            source={'includeAllProducts'}
            label={'Все меню'}
            disabled={!props.isCreation && record.groupId}
          />
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              !formData?.includeAllProducts && (
                <ProductsArrayInput
                  source={'includedProductsIds'}
                  label={'Выбранные блюда'}
                  filterToQueryField={'name'}
                  filter={{ unitId: formData.unitId }}
                  disabled={!props.isCreation && record.groupId}
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
        </FormGroup>
      )}

      {promo?.settings.canChangeOrderAmount && (
        <NumberInput
          source={'minOrderAmount'}
          label={'Минимальная сумма заказа'}
          disabled={!props.isCreation && record.groupId}
        />
      )}

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          promo?.type === PromoType.Gift && (
            <FormGroup title={'Выберите блюда подарки'}>
              <ProductsArrayInput
                source={'productGiftIds'}
                label={'Выбранные блюда'}
                filterToQueryField={'name'}
                filter={{ unitId: formData.unitId }}
                helperText={'Выбрать можно только один товар'}
                disabled={!props.isCreation && record.groupId}
                validate={required()}
              />
            </FormGroup>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {() =>
          promo?.settings.canSchedule && (
            <BooleanInput
              source="hasSchedule"
              label={'Есть расписание'}
              fullWidth
              disabled={!props.isCreation && record.groupId}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          promo?.settings.canSchedule &&
          formData.hasSchedule && (
            <WorkScheduleMultiTimeInput
              source={'schedule'}
              label={'Расписание'}
              disabled={!props.isCreation && record.groupId}
              withLimit={formData.promotionType === PromotionType.Automatic}
            />
          )
        }
      </FormDataConsumer>
    </Box>
  )
}

enum FormStep {
  Partner = 'partner',
  Unit = 'unit',
  Promo = 'promo',
  Details = 'details',
}

const PromoTab = (props: Props) => {
  const form = useFormContext()
  const record = useRecordContext()
  const [step, setStep] = useState<FormStep>(form.getValues('promoId') ? FormStep.Details : FormStep.Partner)
  //
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])
  //
  const formValues = form.watch()

  const handleChangePromo = (_: IPromo) => {
    setStep(FormStep.Details)
  }

  const handleChangeUnit = (val: number) => {
    setStep(FormStep.Promo)
    form.reset()
    form.setValue('countryId', formValues.countryId)
    form.setValue('partnerId', formValues.partnerId)
    form.setValue('unitId', val)
    form.setValue('promoId', null)
  }

  const handleChangePartner = (val: number) => {
    setStep(val ? FormStep.Unit : FormStep.Partner)
    form.reset()
    form.setValue('countryId', formValues.countryId)
    form.setValue('partnerId', val)
    form.setValue('promoId', null)
    form.setValue('unitId', null)
  }

  const handleChangeCountry = (val: string) => {
    setStep(FormStep.Partner)
    form.reset()
    form.setValue('countryId', val)
    form.setValue('partnerId', null)
    form.setValue('promoId', null)
    form.setValue('unitId', null)
    form.resetField('includedCategoriesIds')
    form.resetField('excludedCategoriesIds')
  }

  return (
    <>
      <CountryInput
        source={'countryId'}
        label={'Страна'}
        allowEmpty={false}
        onChange={handleChangeCountry}
        fullWidth
        validate={required()}
      />

      {(formValues.countryId || !isDandbProject()) && (
        <IfCanAccess aggregator action={'show'}>
          <PartnerInput
            resettable
            fullWidth
            source="partnerId"
            label={'Партнер'}
            filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
            onChange={handleChangePartner}
            disabled={!props.isCreation && record.groupId}
          />
        </IfCanAccess>
      )}
      {[FormStep.Promo, FormStep.Unit, FormStep.Details].includes(step) && (
        <UnitInput
          source={'unitId'}
          showBrand={true}
          fullWidth={true}
          resettable={false}
          allowEmpty={false}
          onChange={handleChangeUnit}
          filter={{ ...(formValues.partnerId ? { 'brand.partnerId': formValues.partnerId } : {}) }}
          validate={required()}
          disabled={!props.isCreation && record.groupId}
        />
      )}

      {step == FormStep.Promo && (
        <PromoServiceDeliverySelectPageInput
          source={'promoId'}
          isTakeaway={isTakeaway}
          onChange={handleChangePromo}
          filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
          disabled={!props.isCreation && record.groupId}
        />
      )}
      {step == FormStep.Details && <Details isCreation={props.isCreation} />}
      {!props.isCreation && !record.groupId && (
        <RecordChanges
          recordType={RecordType.PromoUnit}
          statusList={PromoUnitStatusList}
          additionalMap={[
            { key: 'promotionType', label: 'Тип акции', choices: PromotionTypeList },
            { key: 'includeAllProducts', label: 'Блюда меню участвующие (все меню)' },
            { key: 'includedProductsIds', label: 'Выбранные блюда', valueBuilder: (values) => values.join(', ') },
            { key: 'includeAllCategories', label: 'Категории меню участвующие (все меню)' },
            {
              key: 'includedCategoriesIds',
              label: 'Товарные разделы участвующие',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'excludedCategoriesIds',
              label: 'Товарные разделы исключения',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'includedProductCategoriesIds',
              label: 'Выбранные категории',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'excludedProductCategoriesIds',
              label: 'Категории меню исключения',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'productGiftIds',
              label: 'Блюда подарки',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'minOrderAmount',
              label: 'Минимальная сумма заказа',
            },
            {
              key: 'schedule',
              label: 'Расписание',
              valueBuilder: Formatter.formatScheduleForRender,
            },
            {
              key: 'promo',
              label: 'Акция',
              valueBuilder: (promo) => `ID: ${promo?.id}. Название: ${promo?.name}`,
            },
          ]}
        />
      )}
    </>
  )
}

const PromoUnitForm = (props: any) => {
  const record = useRecordContext()

  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      defaultValues={{ status: PromoUnitStatus.Active }}
      toolbar={
        <Toolbar className={modalFormStyles.toolbar}>
          <SaveButton />
          {!props.isCreation && !record.groupId && <DeleteButton />}
        </Toolbar>
      }
    >
      <PromoTab isCreation={props.isCreation} />
    </SimpleForm>
  )
}

export default PromoUnitForm
