import * as React from 'react'
import {
  TabbedForm,
  TextInput,
  FormTab,
  SelectInput,
  required,
  RadioButtonGroupInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  useResourceContext,
} from 'react-admin'
import { PromoKind, PromoKindList } from 'src/types/enum/PromoKind'
import RegionInput from 'src/components/inputs/ReqionInput'
import { PromoPayer, PromoPayerList } from 'src/types/enum/PromoPayer'
import PromoFormViewTab from 'src/components/Promo/PromoFormViewTab'
import { PromoType } from 'src/types/enum/PromoType'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'
import { PromoTemplate } from 'src/types/enum/PromoTemplate'
import { PromoGroupList } from 'src/types/enum/PromoGroup'
import { PromoRecordChanges } from '../../components/PromoRecordChanges'
import CountryInput from '../../components/inputs/CountryInput'
import { useState } from 'react'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'
import { useFormContext } from 'react-hook-form'

const InfoTab = (props: any) => {
  return (
    <FormTab label="Акция" path={'benefit'} {...props}>
      <BooleanInput source="published" label={'Активен'} fullWidth />
      <RadioButtonGroupInput source="kind" label={''} choices={PromoKindList} validate={[required()]} fullWidth />
      <SelectInput source="group" label={'Группа'} choices={PromoGroupList} resettable={true} fullWidth />
      <NumberInput source={'sort'} label={'Сортировка'} />
      <SelectInput
        source={'payer'}
        label={'Плательщик'}
        disabled={props.isEdit}
        choices={PromoPayerList}
        validate={[required()]}
        fullWidth
        helperText={'Можно выбрать только при создании новой акции'}
      />
      <TextInput
        source="key"
        label={'Ключ'}
        helperText={'Нужен для интеграций например iiko'}
        fullWidth
        validate={[required()]}
      />
      <TextInput
        source="innerName"
        label={'Внутреннее название'}
        helperText={'Отображается только в лк партнера и агрегататора'}
        fullWidth
      />
      <TextInput
        source="discountValue"
        label={'Процент скидки'}
        helperText={
          'Используетя для вывода скидки на точках на карте и в карточке ресторана для бейджей какие акции есть в ресторана'
        }
        fullWidth
      />
    </FormTab>
  )
}

const ConditionsTab = (props: any) => {
  const resource = useResourceContext()
  const form = useFormContext()
  //
  const [countryId, setCountryId] = useState<string | null>(null)
  //
  return (
    <FormTab label="Условия" path={'benefit'} {...props}>
      <CountryInput
        source={'countryId'}
        label={'Страна'}
        allowEmpty={false}
        fullWidth
        onChange={(val) => {
          setCountryId(val)
          form.resetField('categoriesIds')
        }}
        validate={required()}
      />
      <RegionInput
        source={'regionId'}
        label={'Регион'}
        emptyText={'Все'}
        fullWidth
        filter={getCountryIdFilterBasedOnProject(countryId)}
      />
      <DateTimeInput label="Начало действия" source="startsAt" variant={'outlined'} fullWidth={true} />
      <DateTimeInput label="Окончание действия" source="endsAt" variant={'outlined'} fullWidth={true} />
      <CategoriesArrayInput
        source={'categoriesIds'}
        label={'Товарные разделы'}
        filter={{
          ...getCountryIdFilterBasedOnProject(countryId),
          serviceType: ServiceTypeDictionary.convertResource(resource),
        }}
        fullWidth
      />

      <BooleanInput source="worksWithoutSubscription" label={'Можно применить без подписки'} fullWidth />
      <BooleanInput source="settings.canSchedule" label={'Может использовать расписание'} fullWidth />
    </FormTab>
  )
}

const PromoForm = (props: any) => {
  return (
    <>
      <TabbedForm
        {...props}
        redirect={'list'}
        defaultValues={{
          type: PromoType.Sale,
          payer: PromoPayer.Partner,
          kind: PromoKind.Public,
          published: true,
          template: PromoTemplate.WithImageGradient,
        }}
      >
        <InfoTab isEdit={props.isEdit} />
        <PromoFormViewTab />
        <ConditionsTab />
      </TabbedForm>
      {props.isEdit && <PromoRecordChanges />}
    </>
  )
}

export default PromoForm
