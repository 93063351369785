export enum FacilityType {
  Restaurant = 'restaurant',
  Cafe = 'cafe',
  Bakery = 'bakery',
  PastryShop = 'pastryShop',
  Kitchen = 'kitchen',
  Pizzeria = 'pizzeria',
  DumplingHouse = 'dumplingHouse',
  Bistro = 'bistro',
  Bar = 'bar',
  HookahBar = 'hookahBar',
}

export const FacilityTypeList = [
  {
    id: FacilityType.Restaurant,
    name: 'Ресторан',
  },
  {
    id: FacilityType.Cafe,
    name: 'Кафе',
  },
  {
    id: FacilityType.Bakery,
    name: 'Пекарня',
  },
  {
    id: FacilityType.PastryShop,
    name: 'Кондитерская',
  },
  {
    id: FacilityType.Kitchen,
    name: 'Кухня',
  },
  {
    id: FacilityType.Pizzeria,
    name: 'Пиццерия',
  },
  {
    id: FacilityType.DumplingHouse,
    name: 'Пельменная',
  },
  {
    id: FacilityType.Bistro,
    name: 'Бистро',
  },
  {
    id: FacilityType.Bar,
    name: 'Бар',
  },
  {
    id: FacilityType.HookahBar,
    name: 'Кальянная',
  },
]
