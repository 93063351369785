import * as React from 'react'
import * as _ from 'lodash'
import get from 'lodash/get'
import { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import { FieldProps } from 'react-admin'
import { useMemo } from 'react'
import { OrderFieldsMap } from 'src/types/enum/OrderFields'
import styles from './index.module.scss'
import { AllOrdersStatusesList } from 'src/types/enum/OrderStatus'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import { DeliveryMethodList } from 'src/types/enum/DeliveryMethod'
import { PlatformList } from 'src/types/enum/Plaform'
import { PaymentStatusList } from 'src/types/enum/PaymentStatus'
import { format } from 'date-fns'
import Formatter from 'src/utils/formatters'

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {}

export const OrderChangedDataField = (props: Props) => {
  const { source } = props
  const record = useRecordContext(props)

  const value = useMemo(() => {
    const value = get(record, source ?? '')
    const finalValueObj = Array.isArray(value) ? value.reduce((acc, value) => ({ ...acc, ...value }), {}) : { ...value }

    if (value?.paymentData?.data?.orderId) {
      finalValueObj.orderId = value.paymentData?.data?.orderId
    }
    if (value.paymentData?.data?.orderNumber) {
      finalValueObj.orderNumber = value.paymentData?.data?.orderNumber
    }
    delete finalValueObj.paymentData
    //
    return finalValueObj
  }, [])

  const list = useMemo((): Array<{ label: string; value: string; key: string }> => {
    if (Array.isArray(value)) {
      return []
    }
    const keys = Object.keys(value)
    const fieldsMap = OrderFieldsMap as any
    //
    return _.filter(
      keys.map((i) => {
        if (_.isNil(value[i])) {
          return null
        }
        if (i === 'cancelReason') {
          return null
        }
        return {
          label: fieldsMap[i as any],
          value: value[i],
          key: i,
        }
      }),
    ) as unknown as Array<{ label: string; value: string; key: string }>
  }, [value])

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case 'address':
        let address = ''

        if (value?.city) {
          address += `\n\tГород: ${value.city}`
        }
        if (value?.address) {
          address += `\n\tАдрес: ${value.address}`
        }
        if (value?.street) {
          address += `\n\tУлица: ${value.street}`
        }
        if (value?.house) {
          address += `\n\tДом: ${value.house}`
        }
        if (value?.entrance) {
          address += `\n\tПодъезд: ${value.entrance}`
        }
        if (value?.floor) {
          address += `\n\tЭтаж: ${value.floor}`
        }
        if (value?.apartment) {
          address += `\n\tКв/офис: ${value.apartment}`
        }
        if (value?.intercomCode) {
          address += `\n\tКод домофона: ${value.intercomCode}`
        }
        return address
      case 'location':
        return `${value?.lat}, ${value?.lng}`
      case 'status':
      case 'prevStatus':
        return AllOrdersStatusesList.find((i) => i.id === value)?.name
      case 'paymentMethod':
        return PaymentMethodList.find((i) => i.id === value)?.name
      case 'deliveryMethod':
        return DeliveryMethodList.find((i) => i.id === value)?.name
      case 'totalDiscount':
      case 'totalDelivery':
      case 'total':
      case 'moneyChange':
        return Formatter.formatIntPrice(value)
      case 'platform':
        return PlatformList.find((i) => i.id === value)?.name
      case 'isPreOrder':
        return value === true ? 'Да' : 'Нет'
      case 'paymentStatus':
        return PaymentStatusList.find((i) => i.id === value)?.name
      case 'preOrderAt':
      case 'exceptedDeliveryAt':
      case 'cookedAt':
      case 'deliveredAt':
      case 'paidAt':
        if (!value) {
          return ''
        }
        return format(new Date(value), 'dd.MM.y HH:mm xxx')
      case 'lateTime':
        return `${value} мин`
      case 'phone':
        return Formatter.formatPhone(value)
      default:
        return value ? value : ''
    }
  }

  return (
    <div className={styles.root}>
      {list
        .filter((i) => !Array.isArray(i.value) && !Array.isArray(i.label))
        .map((field, index) => (
          <div key={index}>
            <Typography component="span" variant="body2" whiteSpace={'pre-wrap'}>
              {field.label ?? ''}: {`${formatValue(field.key, field.value)}` ?? ''}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default OrderChangedDataField
