import * as React from 'react'
import * as _ from 'lodash'
import { SimpleForm, NumberInput, TextInput, required, SelectInput, BooleanInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'

type Props = {
  isCreation: boolean
}

const Form = (props: Props) => {
  const record = useRecordContext()
  const form = useFormContext()
  const formValues = form.watch()

  return (
    <>
      <TextInput source="code" label={'Промокод'} helperText={''} fullWidth validate={[required()]} />
      {!_.isNumber(record?.days) && !formValues.isComebackPromoCode && (
        <SelectInput
          source={'month'}
          label="Кол-во месяцев"
          helperText={'Кол-во месяцев бесплатной подписки (Триала)'}
          resettable={false}
          validate={[required()]}
          choices={_.range(1, 24, 1).map((i) => ({ id: i, name: `${i}` }))}
        />
      )}
      <NumberInput source="usageLimit" label={'Общий лимит на промокод'} helperText={''} fullWidth />
      <NumberInput
        source="perCustomerUsageLimit"
        label={'Лимит на промокод на пользователя'}
        helperText={''}
        fullWidth
      />
      {_.isNumber(record?.days) && (
        <Typography>Промокод предназначен для бесплатной подписки на 14 дней при отмене подписки</Typography>
      )}
      {props.isCreation && (
        <BooleanInput
          source="isComebackPromoCode"
          label={'Промокод на бесплатную подписку на 14 дней при отмене подписки'}
          fullWidth
        />
      )}
    </>
  )
}

const PromoCodeSubscriptionForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <Form {...props} />
    </SimpleForm>
  )
}
export default PromoCodeSubscriptionForm
