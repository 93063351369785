import * as React from 'react'
import * as _ from 'lodash'
import {
  TextInput,
  SimpleForm,
  FormDataConsumer,
  required,
  useGetOne,
  SelectInput,
  BooleanInput,
  NumberInput,
} from 'react-admin'
import { PromoUnitStatus, PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import UnitArrayInput from 'src/components/inputs/UnitArrayInput'
import { useFormContext } from 'react-hook-form'
import { IPromo } from 'src/types/interfaces/IPromo'
import Box from '@mui/material/Box'
import PromoCardWithDetails from 'src/components/Promo/PromoCardWithDetails'
import FormGroup from 'src/components/FormGroup'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import { ServiceType } from 'src/types/enum/ServiceType'
import ProductCategoriesArrayInput from 'src/components/inputs/ProductCategoriesArrayInput'
import ProductsArrayInput from 'src/components/inputs/ProductsArrayInput'
import { PromoType } from 'src/types/enum/PromoType'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import { useMemo } from 'react'
import PromoServiceDeliveryInput from 'src/components/inputs/PromoServiceDeliveryInput'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject, isDandbProject } from '../../components/list/tree/utils'
import { RecordChanges, RecordType } from '../../components/RecordChangeList'
import Formatter from '../../utils/formatters'

type Props = {
  isCreation: boolean
}

const InfoTab = (props: Props) => {
  const form = useFormContext()
  const formValues = form.watch()
  //
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])

  const unitsIdsFilterValue: number[] = useMemo(
    () => (_.isArray(formValues.unitsIds) && !_.isEmpty(formValues.unitsIds) ? formValues.unitsIds : [0]),
    [formValues.unitsIds],
  )

  const unitIdFilterValue: number = useMemo(() => unitsIdsFilterValue[0], [unitsIdsFilterValue])

  return (
    <>
      <IfCanAccess aggregator action={'show'}>
        <TextInput source="name" label={'Название группы'} fullWidth />

        <CountryInput
          source={'countryId'}
          label={'Страна'}
          allowEmpty={false}
          fullWidth
          onChange={(val) => {
            form.reset()
            form.setValue('name', formValues.name)
            form.setValue('countryId', val)
            form.resetField('includedCategoriesIds')
            form.resetField('excludedCategoriesIds')
          }}
          validate={required()}
        />

        <UnitArrayInput
          source={'unitsIds'}
          label={'Заведения'}
          fullWidth
          allowEmpty={false}
          filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
          optionText={(i) => (i ? `${i?.brand?.name} - ${i?.name}` : '').replaceAll(' undefined', '')}
          disabled={isDandbProject() && !formValues.countryId}
          validate={required()}
        />
      </IfCanAccess>

      <PromoServiceDeliveryInput
        resettable={true}
        source="promoId"
        label={'Акция'}
        isTakeaway={isTakeaway}
        fullWidth
        disabled={isDandbProject() && !formValues.countryId}
        validate={required()}
      />

      <Details {...props} unitIdFilterValue={unitIdFilterValue} unitsIdsFilterValue={unitsIdsFilterValue} />

      {!props.isCreation && (
        <RecordChanges
          recordType={RecordType.PromoUnitGroup}
          statusList={PromoUnitStatusList}
          additionalMap={[
            { key: 'includeAllProducts', label: 'Блюда меню участвующие (все меню)' },
            { key: 'includedProductsIds', label: 'Выбранные блюда', valueBuilder: (values) => values.join(', ') },
            { key: 'includeAllCategories', label: 'Категории меню участвующие (все меню)' },
            {
              key: 'includedCategoriesIds',
              label: 'Товарные разделы участвующие',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'excludedCategoriesIds',
              label: 'Товарные разделы исключения',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'includedProductCategoriesIds',
              label: 'Выбранные категории',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'excludedProductCategoriesIds',
              label: 'Категории меню исключения',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'productGiftIds',
              label: 'Блюда подарки',
              valueBuilder: (values) => values.join(', '),
            },
            {
              key: 'minOrderAmount',
              label: 'Минимальная сумма заказа',
            },
            {
              key: 'schedule',
              label: 'Расписание',
              valueBuilder: Formatter.formatScheduleForRender,
            },
            {
              key: 'unitsAdded',
              label: 'Добавлены заведения (ID)',
              valueBuilder: (unitsIds) => unitsIds.join(', '),
            },
            {
              key: 'unitsRemoved',
              label: 'Удалены заведения (ID)',
              valueBuilder: (unitsIds) => unitsIds.join(', '),
            },
            {
              key: 'promo',
              label: 'Акция',
              valueBuilder: (promo) => `ID: ${promo?.id}. Название: ${promo?.name}`,
            },
          ]}
        />
      )}
    </>
  )
}

const Details = ({
  // unitsIdsFilterValue,
  unitIdFilterValue,
}: Props & { unitsIdsFilterValue: number[]; unitIdFilterValue: number }) => {
  const form = useFormContext()
  const countryId = form.watch('countryId')
  //
  const isTakeaway = useMemo(() => window.location.href.includes('-takeaway'), [window.location.href])
  //
  const { data: promo } = useGetOne<IPromo>(isTakeaway ? 'promo-service-takeaway' : 'promo-service-delivery', {
    id: form.getValues('promoId') || 0,
  })

  return (
    <Box>
      <IfCanAccess aggregator action={'show'}>
        <SelectInput source={'status'} label={'Статус'} choices={PromoUnitStatusList} fullWidth />
      </IfCanAccess>

      {promo && <PromoCardWithDetails promo={promo} />}
      {/*{isTakeaway && (*/}
      {/*  <SelectInput*/}
      {/*    source="promotionType"*/}
      {/*    label="Тип акции"*/}
      {/*    choices={PromotionTypeList}*/}
      {/*    validate={required()}*/}
      {/*    fullWidth*/}
      {/*  />*/}
      {/*)}*/}
      {promo?.settings.canIncludeCategories && (
        <FormGroup title={'Товарные разделы участвующие'}>
          <CategoriesArrayInput
            filter={{ ...getCountryIdFilterBasedOnProject(countryId), serviceType: ServiceType.Delivery }}
            source={'includedCategoriesIds'}
            label={'Выбранные разделы'}
          />
        </FormGroup>
      )}
      {promo?.settings.canExcludeCategories && (
        <FormGroup title={'Товарные разделы исключения'}>
          <CategoriesArrayInput
            filter={{ ...getCountryIdFilterBasedOnProject(countryId), type: ServiceType.Delivery }}
            source={'excludedCategoriesIds'}
            label={'Выбранные разделы'}
          />
        </FormGroup>
      )}

      {promo?.settings.canIncludeProductCategories && (
        <FormGroup title={'Категории меню участвующие'}>
          <BooleanInput source={'includeAllCategories'} label={'Все меню'} />
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              !formData?.includeAllCategories && (
                <ProductCategoriesArrayInput
                  source={'includedProductCategoriesIds'}
                  label={'Выбранные категории'}
                  filterToQueryField={'vendorCode'}
                  filter={{ unitId: unitIdFilterValue }}
                  helperText={
                    'Выберите один Id категории товара, номенклатура с таким Id подтянется ко всем городам и цехам, за которым прикреплен данный id'
                  }
                  optionText={(i) =>
                    (i ? `${i?.name} (${i.vendorCode}) - ${i?.brand?.name} ${i?.unit?.name || ''}` : '').replaceAll(
                      ' undefined',
                      '',
                    )
                  }
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
        </FormGroup>
      )}
      {promo?.settings.canExcludeProductCategories && (
        <FormGroup title={'Категории меню исключения'}>
          <ProductCategoriesArrayInput
            source={'excludedProductCategoriesIds'}
            label={'Выбранные категории'}
            filterToQueryField={'vendorCode'}
            filter={{ unitId: unitIdFilterValue }}
            helperText={
              'Выберите один Id категории товара, номенклатура с таким Id подтянется ко всем городам и цехам, за которым прикреплен данный id'
            }
            optionText={(i) =>
              (i ? `${i?.name} (${i.vendorCode}) - ${i?.brand?.name} ${i?.unit?.name}` : '').replaceAll(
                ' undefined',
                '',
              )
            }
          />
        </FormGroup>
      )}

      {promo?.settings.canIncludeProducts && (
        <FormGroup title={'Блюда меню участвующие'}>
          <BooleanInput source={'includeAllProducts'} label={'Все меню'} />
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData }) =>
              !formData?.includeAllProducts && (
                <ProductsArrayInput
                  source={'includedProductsIds'}
                  label={'Выбранные блюда'}
                  filterToQueryField={'vendorCode'}
                  filter={{ unitId: unitIdFilterValue }}
                  helperText={
                    'Выберите один Id товара, номенклатура с таким Id подтянется ко всем городам и цехам, за которым прикреплен данный id'
                  }
                  optionText={(i) =>
                    (i ? `${i?.name} (${i.vendorCode}) - ${i?.brand?.name} ${i?.unit?.name}` : '').replaceAll(
                      ' undefined',
                      '',
                    )
                  }
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
        </FormGroup>
      )}

      {promo?.settings.canChangeOrderAmount && (
        <NumberInput source={'minOrderAmount'} label={'Минимальная сумма заказа'} />
      )}

      {promo?.type === PromoType.Gift && (
        <FormGroup title={'Выберите блюда подарки'}>
          <ProductsArrayInput
            source={'productGiftIds'}
            label={'Выбранные блюда'}
            filterToQueryField={'vendorCode'}
            filter={{ unitId: unitIdFilterValue }}
            helperText={
              'Выберите один Id категории товара, номенклатура с таким Id подтянется ко всем городам и цехам, за которым прикреплен данный id'
            }
            optionText={(i) =>
              (i ? `${i?.name} (${i.vendorCode}) - ${i?.brand?.name} ${i?.unit?.name}` : '').replaceAll(
                ' undefined',
                '',
              )
            }
            validate={required()}
          />
        </FormGroup>
      )}

      {promo?.settings.canSchedule && <BooleanInput source="hasSchedule" label={'Есть расписание'} fullWidth />}

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          promo?.settings.canSchedule &&
          formData.hasSchedule && <WorkScheduleMultiTimeInput source={'schedule'} label={'Расписание'} />
        }
      </FormDataConsumer>
    </Box>
  )
}

const PromoUnitGroupForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ status: PromoUnitStatus.Active }}>
      <InfoTab isCreation={props.isCreation} />
    </SimpleForm>
  )
}

export default PromoUnitGroupForm
