import * as React from 'react'
import {
  Pagination,
  TextField,
  ReferenceManyField,
  DeleteButton,
  BulkDeleteButton,
  useRefresh,
  useDataProvider,
} from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import { ModalButton, ModalCreateButton, ModalEditButton } from 'src/components/Modal/ModalButton'
import { AreaEditModal } from 'src/resources/unit/area/AreaEditModal'
import { AreaCreateModal } from 'src/resources/unit/area/AreaCreateModal'
import { useRecordContext } from 'ra-core'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { AreaImportModal } from 'src/resources/unit/area/AreaImportModal'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'
import PauseIcon from '@mui/icons-material/Pause'
import { UnitPauseModal } from 'src/resources/unit/UnitPauseModal'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import { UnitAddDeliveryTimeModal } from 'src/resources/unit/UnitAddDeliveryTimeModal'
import AreaStatusField from 'src/components/fields/AreaStatusField'
import IconButton from '@mui/material/IconButton'

interface Props {
  record?: any
}

const ActionFields = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const handleAreaStatusToggling = async () => {
    await dataProvider.update('area', {
      id: record.id,
      data: {
        published: !record.published,
      },
      previousData: record,
    })
    refresh()
  }

  return (
    <Box>
      <ButtonGroup size="small" aria-label="small button group">
        <ModalButton
          label={'Пауза'}
          type={'iconButton'}
          icon={<PauseIcon />}
          key={'import'}
          modal={<UnitPauseModal resource={'area'} />}
        />
        <ModalButton
          label={'Время доставки'}
          type={'iconButton'}
          icon={<MoreTimeIcon />}
          key={'import'}
          modal={<UnitAddDeliveryTimeModal resource={'area'} />}
        />
        <IconButton
          key={'activate'}
          title={record.published ? 'Деактивировать' : 'Активировать'}
          type={'button'}
          onClick={handleAreaStatusToggling}
        >
          {record.published ? <LockOutlined /> : <LockOpenOutlined />}
        </IconButton>
      </ButtonGroup>
    </Box>
  )
}

export const UnitAreaList = (props: Props) => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      {...props}
      reference="area"
      target="unitId"
      sort={{ field: 'id', order: 'ASC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'area'}
        emptyTitle={'Нет зон доставки'}
        bulkActionButtons={
          <BulkDeleteButton mutationMode={'pessimistic'} confirmTitle={'Удалить выбранные зоны доставки?'} />
        }
        actions={[
          <ModalCreateButton key={'create'} modal={<AreaCreateModal mainRecord={record} />} />,
          <ModalButton
            label={'Импорт'}
            icon={<UploadFileIcon />}
            key={'import'}
            modal={<AreaImportModal mainRecord={record} />}
          />,
        ]}
        createButton={
          <>
            <ModalCreateButton key={'create'} modal={<AreaCreateModal mainRecord={record} />} />
            <ModalButton
              label={'Импорт'}
              icon={<UploadFileIcon />}
              key={'import'}
              modal={<AreaImportModal mainRecord={record} />}
            />
          </>
        }
      >
        <TextField source={'name'} label={'Имя'} />
        <TextField source={'price'} label={'Стоимость'} />
        <TextField source={'minOrderPrice'} label={'Мин. Сумма заказа'} />
        <TextField source={'deliveryTime'} label={'Время доставки'} />
        <AreaStatusField source={'status'} label={'Статус'} />
        <ActionFields />
        <EditListButtons
          editButton={<ModalEditButton label={''} modal={<AreaEditModal mainRecord={record} />} />}
          deleteButton={<DeleteButton redirect={false} label={''} />}
        />
      </RelatedList>
    </ReferenceManyField>
  )
}
