import * as React from 'react'
import {
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  FormDataConsumer,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  TextField,
} from 'react-admin'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'
import { useFormContext } from 'react-hook-form'
import UnitInput from '../../components/inputs/UnitInput'
import { ModificationGroupTypeList } from '../../types/enum/ModificationGroupType'
import ProductInput from '../../components/inputs/ProductInput'
import { ModificationType, ModificationTypeList } from '../../types/enum/ModificationType'

export const ModificationGroupForm = (props: any) => {
  const form = useFormContext()

  const handleChangeCountry = (val: string) => {
    form.setValue('countryId', val)
    form.setValue('unitId', null)
    form.setValue('productId', null)
  }

  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ sort: 0 }}>
      <TextInput source={'name'} label={'Название'} validate={required()} />
      <CountryInput
        source={'unit.countryId'}
        label={'Страна'}
        allowEmpty={false}
        onChange={handleChangeCountry}
        fullWidth
        validate={required()}
      />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) => (
          <>
            <UnitInput
              source={'unitId'}
              label={'Заведение'}
              validate={required()}
              filter={getCountryIdFilterBasedOnProject(formData.countryId)}
              fullWidth
            />
            <ProductInput
              source={'productId'}
              label={'Блюдо'}
              validate={required()}
              unitId={formData.unitId || 0}
              fullWidth
            />
          </>
        )}
      </FormDataConsumer>
      <SelectInput source={'type'} label={'Тип'} choices={ModificationGroupTypeList} validate={required()} fullWidth />
      <NumberInput source={'minNum'} label={'Минимальное количество'} fullWidth />
      <NumberInput source={'maxNum'} label={'Максимальное количество'} fullWidth />
      <TextInput source={'vendorCode'} label={'Код добавки'} validate={required()} fullWidth />
      <NumberInput
        source={'sort'}
        label={'Сортировка'}
        helperText='Если не указать сортировку (все оставить 0) - тогда сортировка по умолчанию "Обязательные сначала"'
        validate={required()}
        fullWidth
        sx={{ mb: 2 }}
      />
      <ArrayInput source="modifications" label={'Добавки'} fullWidth>
        <SimpleFormIterator fullWidth>
          <TextField source={'id'} label={'ID'} hidden />
          <SelectInput
            source="type"
            label={'Тип'}
            choices={ModificationTypeList}
            defaultValue={ModificationType.Product}
            validate={required()}
            fullWidth
          />
          <TextInput source="name" label={'Название'} validate={required()} fullWidth />
          <NumberInput source="price" label={'Стоимость'} validate={required()} fullWidth />
          <NumberInput source="brutto" label={'Брутто'} fullWidth />
          <TextInput source={'vendorCode'} label={'Код'} validate={required()} fullWidth />
          <BooleanInput source="isAvailable" label={'Доступно'} validate={required()} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}
