import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { ApiKeyForm } from './ApiKeyForm'

const ApiKeyEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <ApiKeyForm isCreation={false} />
    </Edit>
  )
}
export default ApiKeyEdit
