import * as React from 'react'
import { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { DistrictForm } from './DistrictForm'

const DistrictEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <DistrictForm />
    </Edit>
  )
}
export default DistrictEdit
