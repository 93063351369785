import { Key } from '@mui/icons-material'
import ApiKeyList from './ApiKeyList'
import ApiKeyEdit from './ApiKeyEdit'
import ApiKeyCreate from './ApiKeyCreate'

export default {
  create: ApiKeyCreate,
  edit: ApiKeyEdit,
  list: ApiKeyList,
  icon: Key,
}
