import { AdminRole } from 'src/types/enum/AdminRole'

const View = ['list', 'show']

const Orders = [
  'order',
  'order-active',
  'order-finished',
  'order-refunding',
  'order-takeaway',
  'order-takeaway-active',
  'order-takeaway-finished',
  'order-takeaway-refunding',
]

const PromoUnit = ['promo-unit', 'promo-unit-group', 'promo-unit-takeaway', 'promo-unit-takeaway-group']

const PromoReserves = [
  'promo-reserve',
  'promo-reserve-confirmation',
  'promo-reserve-approved',
  'promo-reserve-canceled',
]

const Banquets = ['banquet', 'banquet-offer', 'banquet-reserve']

const FeaturesAndOccasions = [
  'feature',
  'feature-template',
  'restaurant-feature',
  'occasion',
  'occasion-template',
  'restaurant-occasion',
]

const actionAll = (resources: string[]) => {
  return resources.map((i) => ({ action: '*', resource: i }))
}

export const ROLES = {
  [AdminRole.SuperAdmin]: [{ action: '*', resource: '*' }],
  [AdminRole.AdminPartner]: [
    { action: '*', resource: 'partner' },
    { action: '*', resource: 'brand' },
    { action: '*', resource: 'unit' },
    { action: '*', resource: 'product-category' },
    { action: '*', resource: 'product' },
    ...Orders.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: '*', resource })),
    { action: '*', resource: 'promo-restaurant' },
    { action: '*', resource: 'promo-restaurant-coffee' },
    ...FeaturesAndOccasions.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'review' },
    { action: View, resource: 'reports-promo-reserves' },
    { action: View, resource: 'reports-orders' },
    { action: View, resource: 'country' },
    { action: View, resource: 'region' },
    { action: View, resource: 'area' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: '*', resource: 'admin' },
    { action: '*', resource: 'complaint' },
    { action: '*', resource: 'penalty' },
    { action: View, resource: 'refund' },
    { action: View, resource: 'payment' },
    { action: '*', resource: 'restaurant' },
    { action: '*', resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: '*', resource })),
    { action: 'edit', resource: 'subscription' },
  ],
  [AdminRole.SupportPartner]: [
    { action: View, resource: 'partner' },
    { action: View, resource: 'brand' },
    { action: ['list', 'show', 'edit'], resource: 'unit' },
    { action: ['list', 'show', 'edit'], resource: 'product-category' },
    { action: ['list', 'show', 'edit'], resource: 'product' },
    ...Orders.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'promo-restaurant' },
    { action: View, resource: 'promo-restaurant-coffee' },
    ...FeaturesAndOccasions.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'review' },
    { action: View, resource: 'country' },
    { action: View, resource: 'region' },
    { action: View, resource: 'area' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: View, resource: 'admin' },
    { action: '*', resource: 'penalty' },
    { action: View, resource: 'refund' },
    { action: View, resource: 'payment' },
    { action: ['list', 'show', 'edit'], resource: 'restaurant' },
    { action: ['list', 'show', 'edit'], resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: '*', resource })),
    { action: 'edit', resource: 'subscription' },
  ],
  [AdminRole.Operator]: [
    { action: View, resource: 'partner' },
    { action: View, resource: 'brand' },
    { action: View, resource: 'unit' },
    { action: View, resource: 'product-category' },
    { action: View, resource: 'product' },
    ...Orders.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'promo-restaurant' },
    { action: View, resource: 'promo-restaurant-coffee' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: ['list', 'show', 'create'], resource: 'penalty' },
    { action: View, resource: 'refund' },
    { action: View, resource: 'payment' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: '*', resource })),
    { action: 'edit', resource: 'subscription' },
    { action: View, resource: 'cancel-reason' },
  ],
  [AdminRole.ClientManager]: [
    { action: View, resource: 'partner' },
    { action: View, resource: 'brand' },
    { action: View, resource: 'unit' },
    { action: View, resource: 'product-category' },
    { action: View, resource: 'product' },
    ...Orders.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'promo-restaurant' },
    { action: View, resource: 'promo-restaurant-coffee' },
    { action: '*', resource: 'review' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: '*', resource: 'admin' },
    { action: View, resource: 'article-news' },
    { action: '*', resource: 'complaint' },
    { action: ['list', 'show', 'create', 'edit'], resource: 'penalty' },
    { action: View, resource: 'promo-code' },
    { action: View, resource: 'subscription-promo-code' },
    ...Banquets.map((resource) => ({ action: '*', resource })),
    { action: ['list', 'show', 'create'], resource: 'refund' },
    { action: View, resource: 'payment' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'cancel-reason' },
  ],
  [AdminRole.ContentManager]: [
    { action: View, resource: 'partner' },
    { action: View, resource: 'brand' },
    { action: View, resource: 'unit' },
    { action: '*', resource: 'promo-service-delivery' },
    { action: '*', resource: 'promo-service-restaurants' },
    { action: '*', resource: 'promo-service-coffee' },
    ...FeaturesAndOccasions.map((resource) => ({ action: '*', resource })),
    { action: '*', resource: 'page' },
    { action: '*', resource: 'partner-offer' },
    { action: '*', resource: 'accounting' },
    { action: '*', resource: 'article-category-news' },
    { action: '*', resource: 'article-news' },
    { action: '*', resource: 'promo-code' },
    { action: '*', resource: 'subscription-promo-code' },
    ...Banquets.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'view-template' },
    { action: View, resource: 'view-template-item' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    { action: View, resource: 'cancel-reason' },
  ],
  [AdminRole.ContentUnit]: [
    { action: View, resource: 'partner' },
    { action: View, resource: 'brand' },
    { action: View, resource: 'unit' },
    { action: '*', resource: 'product-category' },
    { action: '*', resource: 'product' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    { action: View, resource: 'cancel-reason' },
  ],
  [AdminRole.Finance]: [
    { action: View, resource: 'partner' },
    { action: View, resource: 'brand' },
    { action: View, resource: 'unit' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    { action: '*', resource: 'reports-promo-reserves' },
    { action: '*', resource: 'reports-orders' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: View, resource: 'payment' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
  ],
  [AdminRole.PartnerAdmin]: [
    { action: View, resource: 'unit' },
    { action: View, resource: 'product-category' },
    { action: View, resource: 'product' },
    ...Orders.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: ['list', 'show', 'create', 'delete'], resource: 'promo-service-delivery' },
    { action: ['list', 'show', 'create', 'delete'], resource: 'promo-service-restaurants' },
    { action: ['list', 'show', 'create', 'delete'], resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: ['list', 'show', 'create', 'delete'], resource })),
    { action: '*', resource: 'promo-restaurant' },
    { action: '*', resource: 'promo-restaurant-coffee' },
    { action: View, resource: 'review' },
    { action: View, resource: 'reports-promo-reserves' },
    { action: View, resource: 'reports-orders' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: View, resource: 'admin' },
    ...Banquets.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'payment' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: '*', resource })),
    { action: 'edit', resource: 'subscription' },
  ],
  [AdminRole.PartnerUnitAdmin]: [
    { action: View, resource: 'unit' },
    { action: View, resource: 'product-category' },
    { action: View, resource: 'product' },
    ...Orders.map((resource) => ({ action: ['list', 'show', 'edit'], resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: ['list', 'show', 'create', 'delete'], resource: 'promo-service-delivery' },
    { action: ['list', 'show', 'create', 'delete'], resource: 'promo-service-restaurants' },
    { action: ['list', 'show', 'create', 'delete'], resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: ['list', 'show', 'create', 'delete'], resource })),
    { action: '*', resource: 'promo-restaurant' },
    { action: '*', resource: 'promo-restaurant-coffee' },
    { action: View, resource: 'review' },
    { action: View, resource: 'reports-promo-reserves' },
    { action: View, resource: 'reports-orders' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: View, resource: 'admin' },
    ...Banquets.map((resource) => ({ action: '*', resource })),
    { action: View, resource: 'payment' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: '*', resource })),
    { action: 'edit', resource: 'subscription' },
  ],
  [AdminRole.PartnerContent]: [
    { action: '*', resource: 'product-category' },
    { action: '*', resource: 'product' },
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: '*', resource })),
    { action: '*', resource: 'promo-restaurant' },
    { action: '*', resource: 'promo-restaurant-coffee' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
  ],
  [AdminRole.PartnerLineEmployee]: [
    { action: View, resource: 'unit' },
    { action: View, resource: 'product-category' },
    { action: View, resource: 'product' },
    ...Orders.map((resource) => ({ action: ['list', 'show', 'edit'], resource })),
    { action: View, resource: 'promo-service-delivery' },
    { action: View, resource: 'promo-service-restaurants' },
    { action: View, resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'promo-restaurant' },
    { action: View, resource: 'promo-restaurant-coffee' },
    ...Banquets.map((resource) => ({ action: View, resource })),
    { action: View, resource: 'restaurant' },
    { action: View, resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: View, resource })),
  ],
  [AdminRole.PartnerBrandAdmin]: [
    { action: '*', resource: 'brand' },
    { action: '*', resource: 'unit' },
    { action: '*', resource: 'product-category' },
    { action: '*', resource: 'product' },
    ...Orders.map((resource) => ({ action: ['list', 'show', 'edit'], resource })),
    { action: View, resource: 'user' },
    { action: View, resource: 'category-delivery' },
    { action: View, resource: 'category-local' },
    { action: ['list', 'show', 'edit'], resource: 'promo-service-delivery' },
    { action: ['list', 'show', 'edit'], resource: 'promo-service-restaurants' },
    { action: ['list', 'show', 'edit'], resource: 'promo-service-coffee' },
    ...PromoUnit.map((resource) => ({ action: '*', resource })),
    { action: '*', resource: 'promo-restaurant' },
    { action: '*', resource: 'promo-restaurant-coffee' },
    { action: View, resource: 'review' },
    { action: View, resource: 'reports-promo-reserves' },
    { action: View, resource: 'reports-orders' },
    { action: View, resource: 'partner-offer' },
    { action: View, resource: 'accounting' },
    { action: '*', resource: 'admin' },
    ...Banquets.map((resource) => ({ action: '*', resource })),
    { action: '*', resource: 'restaurant' },
    { action: '*', resource: 'restaurant-coffee' },
    ...PromoReserves.map((resource) => ({ action: '*', resource })),
    { action: 'edit', resource: 'subscription' },
  ],
}
