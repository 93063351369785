import * as React from 'react'
import {
  SimpleForm,
  required,
  SelectInput,
  TextInput,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  NumberInput,
  DateTimeInput,
} from 'react-admin'
import { Application, ApplicationItemList, AppStateList, StoreItemList } from 'src/types/enum/Application'

export const AppSettingsForm = (props: any) => {
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      toolbar={
        <Toolbar {...props}>
          <SaveButton />
        </Toolbar>
      }
    >
      <SelectInput source={'application'} label={'Приложение'} choices={ApplicationItemList} validate={required()} />
      <SelectInput
        source={'store'}
        label={'Магазин приложений'}
        choices={StoreItemList}
        validate={required()}
        disabled={!props.isCreation}
      />
      <TextInput source={'minRequiredVersion'} label={'Минимальная допустимая версия'}></TextInput>
      <TextInput source={'currentVersion'} label={'Текущая версия'}></TextInput>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData?.application === Application.ClientApp && (
            <NumberInput
              source={'numberOfUsesOfFreeSubscription'}
              label={'Количество бесплатных использований подписки'}
              fullWidth
              validate={required()}
            />
          )
        }
      </FormDataConsumer>
      <SelectInput source={'state'} label={'Состояние сервиса'} choices={AppStateList} validate={required()} />
      <DateTimeInput source={'stateActiveAt'} label={'Сервис будет активен в'} />
    </SimpleForm>
  )
}
