import * as React from 'react'
import { FC } from 'react'
import { Filter, List, ListProps, TextField, DateField } from 'react-admin'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import RestaurantLinkField from '../../components/fields/RestaurantLinkField'

const _Filter = (props: any) => (
  <Filter {...props}>
    <RestaurantInput source={'restaurantId'} label={'Ресторан'} alwaysOn resettable />
  </Filter>
)

const ApiKeyList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      filters={<_Filter />}
      title="API ключи"
      empty={<EmptyList title={'Нет API ключей'} description={'Вы можете добавить ключ'} buttonText={'Добавить'} />}
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <RestaurantLinkField mainSource="restaurant" label={'Ресторан'} />
        <TextField source="key" label={'Ключ'} />
        <DateField source="createdAt" label={'Создано'} showTime />
        <DateField source="updatedAt" label={'Обновлено'} showTime />
      </Datagrid>
    </List>
  )
}

export default ApiKeyList
