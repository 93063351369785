import * as React from 'react'
import { FC, useMemo } from 'react'
import {
  Filter,
  List,
  ListProps,
  TextField,
  DateField,
  SelectField,
  TextInput,
  SelectInput,
  NumberField,
} from 'react-admin'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import { FeatureTemplateTypeList } from '../../types/enum/FeatureTemplateType'
import CountryInput from '../../components/inputs/CountryInput'
import RegionInput from '../../components/inputs/ReqionInput'
import FeatureInput from '../../components/inputs/FeatureInput'
import { ServiceTypeList } from '../../types/enum/ServiceType'

const _Filter = (props: any) => {
  const isOccasion = useMemo(() => window.location.href.includes('occasion'), [window.location.href])
  //
  return (
    <Filter {...props}>
      <TextInput source={'key:$contL'} label={'ID поля'} resettable />
      <TextInput source={'name:$contL'} label={'Название поля'} resettable alwaysOn />
      <TextInput source={'feature.name:$contL'} label={'Название категории'} resettable alwaysOn />
      <FeatureInput source={'featureId'} label={'Категория'} isOccasion={isOccasion} />
      <SelectInput source={'feature.serviceType'} label={'Тип'} choices={ServiceTypeList} resettable alwaysOn />
      <SelectInput source={'type'} label={'Тип содержимого'} choices={FeatureTemplateTypeList} resettable />
      <CountryInput source={'feature.countryId'} label={'Страна'} resettable />
      <RegionInput source={'feature.regionId'} label={'Регион'} resettable />
    </Filter>
  )
}

const FeatureTemplateList: FC<ListProps> = (props: any) => {
  const isOccasion = useMemo(() => window.location.href.includes('occasion'), [window.location.href])
  //
  return (
    <List
      {...props}
      filters={<_Filter />}
      title={`Поля для ${isOccasion ? 'поводов' : 'особенностей'}`}
      empty={
        <EmptyList
          title={`Нет дополнительных полей для ${isOccasion ? 'поводов' : 'особенностей'}`}
          description={'Вы можете добавить дополнтиельное поле'}
          buttonText={'Добавить дополнительное поле'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="key" label={'ID поля'} />
        <TextField source="name" label={'Название поля'} />
        <TextField source="feature.name" label={'Категория'} />
        <TextField source="feature.region.name" label={'Регион'} />
        <SelectField source="feature.serviceType" label={'Тип'} choices={ServiceTypeList} />
        <SelectField source="type" label={'Тип содержимого'} choices={FeatureTemplateTypeList} />
        <NumberField source="sort" label={'Сортировка'} />
        <DateField source="createdAt" label={'Создано'} showTime />
      </Datagrid>
    </List>
  )
}

export default FeatureTemplateList
