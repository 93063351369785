import * as React from 'react'
import { FormDataConsumer, RecordContextProvider, required, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import SliderInput from 'src/components/inputs/SliderInput'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { CircularProgress } from '@mui/material'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import HistoryIcon from '@mui/icons-material/History'
import { useState } from 'react'
import Spacer from 'src/components/Spacer'
interface FormProps {
  record: any
  refresh: () => void
  onClose?: () => void
  resource: 'unit' | 'area'
}
const Form = (props: FormProps) => {
  const { record, refresh, onClose } = props
  const dataProvider = useDataProvider()
  const [isCancelling, setIsCancelling] = useState(false)
  const handleCancel = async () => {
    setIsCancelling(true)
    const res = await dataProvider.update(props.resource, {
      id: record.id,
      data: { setAddDeliveryTime: 0 },
      previousData: record,
    })
    setIsCancelling(false)
    refresh()
    if (onClose) {
      onClose()
    }
  }
  return (
    <>
      {record.addDeliveryTime > 0 && (
        <>
          <Alert
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={handleCancel}
                startIcon={isCancelling ? <CircularProgress size={18} thickness={2} /> : <HistoryIcon />}
              >
                Отменить
              </Button>
            }
          >
            Сейчас Время доставки увеличено на {record.addDeliveryTime} мин.
          </Alert>
          <Spacer />
        </>
      )}
      <SliderInput
        source="setAddDeliveryTime"
        label={'Укажите на сколько минут'}
        fullWidth
        helperText={false}
        variant={'outlined'}
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.setAddDeliveryTime && (
            <Typography variant="body2" align={'center'}>
              Время доставки будет увеличено на {formData.setAddDeliveryTime} мин.
            </Typography>
          )
        }
      </FormDataConsumer>
    </>
  )
}
interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  onClose?: () => void
  resource: 'unit' | 'area'
}
export const UnitAddDeliveryTimeModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const record = useRecordContext()

  const handleSubmit = async (data: FieldValues) => {
    const res = await dataProvider.update(props.resource, { id: record.id, data: { ...data }, previousData: record })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }

  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{ setAddDeliveryTime: record.addDeliveryTime > 0 ? record.addDeliveryTime : 30 }}
        fullWidth={true}
        maxWidth={'xs'}
        resource={props.resource}
        title={'Увеличить время доставки'}
        onClose={props.onClose}
        saveButtonLabel={'Увеличить'}
        saveButtonIcon={<MoreTimeIcon />}
        save={handleSubmit}
      >
        <Form resource={props.resource} record={record} refresh={refresh} onClose={props.onClose} />
      </ModalForm>
    </RecordContextProvider>
  )
}
