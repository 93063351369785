import { AutocompleteInput, ReferenceInput } from 'react-admin'
import * as React from 'react'
import { omit, isNil } from 'lodash'

interface Props {
  source?: string
  label?: string
  disabled?: boolean
  allowEmpty?: boolean
  resettable?: boolean
  alwaysOn?: boolean
  fullWidth?: boolean
  validate?: any
  unitId?: number
}

const ProductInput = (props: Props) => {
  return (
    <ReferenceInput
      allowEmpty={props.allowEmpty}
      filter={{ ...(!isNil(props.unitId) ? { unitId: props.unitId } : {}) }}
      source={props.source ?? 'productId'}
      reference="product"
      variant={'outlined'}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      {...(omit(props, ['validate']) as any)}
    >
      <AutocompleteInput
        validate={props.validate}
        noOptionsText="-"
        filterToQuery={(q: string) => ({ 'name:$contL': q })}
        optionText={(i) => `${i?.brand?.name} - ${i?.unit?.name} - ${i?.name || ''}`}
        label={props.label ?? 'Блюдо'}
        fullWidth={props.fullWidth}
      />
    </ReferenceInput>
  )
}

export default ProductInput
