import * as React from 'react'
import { RecordContextProvider, useDataProvider, useRefresh } from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import { DeliveryZoneEditForm } from 'src/resources/unit/area/DeliveryZoneEditForm'

interface Props {
  refresh?: boolean
  isShown?: boolean
  id?: number
  mainRecord: Record<any, any>
  onClose?: () => void
}

export const AreaCreateModal = (props: Props) => {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.create('area', { data: { ...data, unitId: props.mainRecord.id } })
    refresh()

    if (props.onClose) {
      props.onClose()
    }
    return true
  }
  //
  return (
    <RecordContextProvider value={{}}>
      <ModalForm
        isShown
        defaultValues={{ preview: 'medium', published: true }}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Новая зона доставки'}
        onClose={props.onClose}
        save={handleSubmit}
      >
        {<DeliveryZoneEditForm />}
      </ModalForm>
    </RecordContextProvider>
  )
}

AreaCreateModal.defaults = {
  refresh: true,
}
