import * as React from 'react'
import { FC, useMemo } from 'react'
import {
  Filter,
  List,
  ListProps,
  TextField,
  DateField,
  TextInput,
  SelectInput,
  SelectField,
  BooleanField,
} from 'react-admin'
import CountryInput from 'src/components/inputs/CountryInput'
import { Datagrid } from 'src/components/list/Datagrid'
import EmptyList from 'src/components/list/EmptyList'
import RegionInput from '../../components/inputs/ReqionInput'
import { ServiceTypeList } from '../../types/enum/ServiceType'
import { isDandbProject } from '../../components/list/tree/utils'
import CountryField from '../../components/fields/CountryField'

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source={'name:$contL'} label={'Название'} resettable alwaysOn />
    <TextInput source={'filterName:$contL'} label={'Название в фильтре'} resettable alwaysOn />
    <SelectInput source={'serviceType'} label={'Тип'} choices={ServiceTypeList} resettable alwaysOn />
    <CountryInput source={'countryId'} label={'Страна'} />
    <RegionInput source={'regionId'} label={'Регион'} />
  </Filter>
)

const FeatureList: FC<ListProps> = (props: any) => {
  const isOccasion = useMemo(() => window.location.href.includes('occasion'), [window.location.href])
  //
  return (
    <List
      {...props}
      filters={<_Filter />}
      title={isOccasion ? 'Поводы' : 'Особенности'}
      empty={
        <EmptyList
          title={`Нет ${isOccasion ? 'поводов' : 'особенностей'}`}
          description={`Вы можете добавить ${isOccasion ? 'повод' : 'особенность'}`}
          buttonText={'Добавить'}
        />
      }
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <TextField source={'id'} label={'ID'} />
        <TextField source="name" label={'Название'} />
        <TextField source="filterName" label={'Название в фильтре'} />
        <SelectField source={'serviceType'} label={'Тип'} choices={ServiceTypeList} />
        {isDandbProject() && <CountryField source={'country.name'} label={'Страна'} />}
        <TextField source="region.name" label={'Регион'} />
        <BooleanField source="published" label={'Активен'} />
        <DateField source="createdAt" label={'Создано'} showTime />
      </Datagrid>
    </List>
  )
}

export default FeatureList
